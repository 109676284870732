'use strict';

const { queryFirst, queryAll, addClass, removeClass } = require('../domUtil');

/**
 * Expands all collapses
 */
function expandAllContent() {
    const expandAll = queryAll('.expand-all');
    expandAll.forEach(expandBtn => {
        expandBtn.addEventListener('click', e => {
            e.preventDefault();
            const { target } = e;
            const currentTab = target.closest('.tab-content');
            const activeCard = queryFirst('.tab-pane.show', currentTab);
            const cards = queryAll('.card-header button', activeCard);
            const content = queryAll('.card-body .collapse', activeCard);
            removeClass(cards, 'collapsed');
            addClass(content, 'show');
            cards.forEach((card) => {
                card.setAttribute('aria-expanded', true);
            });
        });
    });
}

expandAllContent();
