'use strict';

module.exports = {
    listenToCSRFErrors: function () {
        $('body').on('csrf:error', () => {
            const csrfModalEl = $('#csrf-error-modal');

            if (csrfModalEl.length) {
                csrfModalEl.modal('show');
            }
        });
    }
};
