'use strict';

const { queryFirst, toggleClass, removeClass, queryAll, hasClass, outerHeight, addClass } = require('../domUtil');
const { HIDDEN_CLASS, KEYCODE_ESCAPE, KEYCODE_TAB, NO_SCROLL_CLASS, IS_STICKY_CLASS } = require('../constants');

const searchMenuEl = queryFirst('.header-search-type');
const searchClearBtnEls = queryAll('.search-form-clear');
const popularSearchEl = queryFirst('.popular-search');
const bannerCloseEl = queryFirst('.close-banner');
const mainNav = queryFirst('.main-nav');
const bannerEl = queryFirst('.carousel-stripe-banner');
const noSearchResultsCloseEl = queryFirst('.no-search-results-header .search-form-clear');
const noSearchResultsInput = queryFirst('.no-search-results-header .search-field');
const headerSearchMenu = queryFirst('header .search-menu');
const TabKey = 'Tab';
const EscKey = 'Escape';

const bodyEl = document.body;
const showMenuClass = 'open';

const hideSuggestionsMenu = e => {
    const { target } = e;
    const parentEl = target.closest('.search-global');
    const searchSuggEl = queryFirst('.suggestions-wrapper', parentEl);

    addClass(searchSuggEl, HIDDEN_CLASS);
    $('.suggestions').show();
};

const toggleSearchMenu = () => {
    const openingMenu = !hasClass(searchMenuEl, showMenuClass);
    const searchField = queryFirst('.search-field');
    toggleClass(searchMenuEl, showMenuClass);
    const ariaValue = searchMenuEl.getAttribute('aria-expanded') !== 'true';
    searchMenuEl.setAttribute('aria-expanded', ariaValue);
    let timeDelay = openingMenu ? 0 : 500;

    setTimeout(() => {
        toggleClass(bodyEl, NO_SCROLL_CLASS);
    }, timeDelay);

    if (openingMenu) {
        searchField.setAttribute('autofocus', 'autofocus');
        setTimeout(() => {
            searchField.focus();
        }, timeDelay + 300);
    }

    searchField.removeAttribute('autofocus', 'autofocus');

    // show popular search on opening of search menu, hide search suggestions on close of search menu
    if (openingMenu && popularSearchEl) {
        popularSearchEl.style.display = 'block';
    } else if (!openingMenu) {
        const fullSuggEl = queryFirst('.full-suggestions');
        if (fullSuggEl) {
            fullSuggEl.style.display = 'none';
        }
    }
};

const closeSearchMenu = () => {
    removeClass(searchMenuEl, showMenuClass);
    $('.suggestions').hide();
    searchMenuEl.setAttribute('aria-expanded', false);
    setTimeout(() => {
        removeClass(bodyEl, NO_SCROLL_CLASS);
        queryFirst('.search-toggle-btn-main').focus();
    }, 400);
};

const handleSearchMenuClose = e => {
    const { target } = e;
    if (target.matches('#search-close') || target.matches('.header-search-type .fade-bg') || target.matches('.header-search-type .search-form-close')) {
        closeSearchMenu();
    }
};

// Sticky navbar
// =========================
// Custom function which toggles between sticky class (is-sticky)
const stickyToggle = (sticky, stickyWrapper, scrollElement) => {
    const stickyHeight = outerHeight(sticky);
    const stickyTop = stickyWrapper.offset().top;
    if (hasClass(bannerEl, 'd-none')) {
        if (scrollElement > 1) {
            stickyWrapper.height(stickyHeight);
            addClass(sticky, IS_STICKY_CLASS);
        } else {
            removeClass(sticky, IS_STICKY_CLASS);
            stickyWrapper.height('auto');
        }
    } else if (scrollElement >= stickyTop) {
        stickyWrapper.height(stickyHeight);
        addClass(sticky, IS_STICKY_CLASS);
    } else {
        removeClass(sticky, IS_STICKY_CLASS);
        stickyWrapper.height('auto');
    }
};

const checkNoResultsInputLength = e => {
    noSearchResultsCloseEl.classList[e.target.value.length ? 'remove' : 'add'](HIDDEN_CLASS);
};

const handleTabListeners = e => {
    const isEscPressed = e.key === EscKey || e.keyCode === KEYCODE_ESCAPE;
    if (isEscPressed) {
        closeSearchMenu();
        return;
    }

    const isTabPressed = e.key === TabKey || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
        return;
    }
    const focusable = queryAll('button:not(.d-md-none), [href]:not(.d-md-none), [tabindex]:not([tabindex="-1"])', headerSearchMenu);
    const lastFocusable = focusable[focusable.length - 1];
    const searchField = queryFirst('.search-field');

    if (e.target === lastFocusable) {
        searchField.setAttribute('autofocus', 'autofocus');
        setTimeout(() => {
            searchField.focus();
        }, 300);
    }
};

module.exports = () => {
    const searchToggles = queryAll('.search-toggle-btn, .search-toggle-btn-main, .try-search-again-btn');

    if (bannerCloseEl) {
        bannerCloseEl.addEventListener('click', () => {
            removeClass(mainNav, 'has-banner');
        });
    }

    searchToggles.forEach(element => {
        element.addEventListener('click', toggleSearchMenu);
    });
    if (searchMenuEl) {
        searchMenuEl.addEventListener('click', handleSearchMenuClose);
    }
    if (searchClearBtnEls) {
        searchClearBtnEls.forEach(btn => {
            btn.addEventListener('click', hideSuggestionsMenu);
        });
    }

    if (noSearchResultsInput) {
        noSearchResultsInput.addEventListener('input', checkNoResultsInputLength);
    }

    if (headerSearchMenu) {
        headerSearchMenu.addEventListener('keydown', handleTabListeners);
    }

    // Find all data-toggle="sticky-onscroll" elements
    $('[data-toggle="sticky-onscroll"]').each(() => {
        const head = queryFirst('.main-header');
        const stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
        $(head).before(stickyWrapper);
        addClass(head, 'sticky');
        // Scroll & resize events
        $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', () => {
            stickyToggle(head, stickyWrapper, $(window).scrollTop());
        });

        // On page load
        stickyToggle(head, stickyWrapper, $(window).scrollTop());
    });
};
