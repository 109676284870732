window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

const { initCarousel } = require('./components/carousel');
const { initLiveChat } = require('liveagent/liveagent');
const { initSMS } = require('liveagent/sms');
const { initEmailLightbox } = require('./components/emailLightbox');
const { initPopoverAll } = require('./popover');

require('./polyfill');
require('./jquery.plugins.js');
require('bootstrap/js/src/popover');
require('bootstrap/js/src/dropdown.js');
require('jquery-zoom');

document.addEventListener('DOMContentLoaded', () => {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/headerSearch'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/modal'));
    processInclude(require('./components/toolTip'));
    initPopoverAll();
    processInclude(require('./postLogin/postLogin'));
    processInclude(require('./animateUtil'));
    processInclude(require('./components/video'));
    processInclude(require('./product/sizeChart'));
    processInclude(require('./product/wishlist'));
    processInclude(require('./components/csrfErrorModal'));
    processInclude(require('./components/serviceWorkerDeRegistration'));
    processInclude(require('./components/textAreaCounter'));
    processInclude(require('./components/postalCode'));
    processInclude(require('./imageLazyLoad'));
    processInclude(require('./scriptLazyLoad'));
    processInclude(require('./loyalty/loyaltyDrawer'));
    processInclude(require('./components/updateURLPath'));
    processInclude(require('./onetrust'));
    processInclude(require('./components/pillsMenu'));
    processInclude(require('./components/accordion'));
    processInclude(require('./exposedFunctions'));
    processInclude(require('./togglePassword'));
    initEmailLightbox();
    initCarousel();
    initLiveChat();
    initSMS();
    $('.invalid-feedback').attr('role', 'alert');
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('./components/staggeredContent');
