'use strict';

const { queryFirst, addClass, queryAll, removeClass, hasClass } = require('./domUtil.js');

const togglePassword = queryAll('.toggle-password');
togglePassword.forEach(eachIcon => {
    eachIcon.addEventListener('click', function () {
        const currentFormGroup = this.closest('.form-group');
        const currentPasswordInput = queryFirst('input', currentFormGroup);
        currentPasswordInput.focus();
        if (hasClass(this, 'show-password')) {
            removeClass(this, 'show-password');
            currentPasswordInput.type = 'password';
        } else {
            addClass(this, 'show-password');
            currentPasswordInput.type = 'text';
        }
    });
});
