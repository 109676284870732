'use strict';

const controlKeys = ['8', '9', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

$('body')
    .on('keydown', 'textarea[data-character-limit]', function (e) {
        const textAreaEl = $(this);
        const text = (textAreaEl.val() || '').trim();
        const charsLimit = textAreaEl.data('character-limit');
        const charsUsed = text.length;

        if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
            e.preventDefault();
        }
    })
    .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
        const textAreaEl = $(this);
        const text = (textAreaEl.val() || '').trim();
        const charsLimit = textAreaEl.data('character-limit');
        let charsUsed = text.length;

        if (charsUsed > charsLimit) {
            textAreaEl.val(text.slice(0, charsLimit));
            charsUsed = charsLimit;
        }

        textAreaEl.next('div.char-count').find('.current-char-count').html(charsUsed);
    });

$('form').find('textarea[data-character-limit]').each(function () {
    const textAreaEl = $(this);

    const characterLimit = textAreaEl.data('character-limit');
    textAreaEl.removeAttr('maxlength');

    const charCountHtml = `<span class="current-char-count">${0}</span>/<span class="char-allowed-count">${characterLimit}</span>`;

    const charCountContainer = textAreaEl.next('div.char-count');
    if (charCountContainer.length === 0) {
        charCountContainer = $('<div class="char-count"></div>').insertAfter(textAreaEl);
    }
    charCountContainer.html(charCountHtml);

    // trigger the keydown event so that any existing character data is calculated
    textAreaEl.change();
});
