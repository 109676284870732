'use strict';

const { addClass, queryFirst, queryAll } = require('lilly/domUtil');

const liveChatElement = queryFirst('#livechatEnabled');
const liveChatConfig = liveChatElement ? liveChatElement.dataset : {};
const isLiveChatEnabled = liveChatElement && liveChatElement.value === 'true';
const { buttonId, chatEndpoint, deploymentId, organizationId, windowHeight, windowWidth } = liveChatConfig;

const WINDOW_NAME = 'lilly-livechat';
let win;

/**
 * Opens the Live Chat window
 */
function startChat() {
    if (win && !win.closed) return win.focus();

    const { availWidth, availHeight, availLeft, availTop } = screen;

    win = window.open('about:blank', WINDOW_NAME, 'toolbar=0,location=0,menubar=0');

    if (windowWidth && windowHeight) {
        liveagent.setChatWindowWidth(windowWidth);
        liveagent.setChatWindowHeight(windowHeight);

        win.resizeTo(windowWidth, windowHeight);
        win.moveTo(availLeft + ((availWidth - parseInt(windowWidth, 10)) / 2), availTop + ((availHeight - parseInt(windowHeight, 10)) / 2));
    }

    liveagent.startChatWithWindow(buttonId, WINDOW_NAME);
}

/**
 * Initializes live agent configuration and events
 */
function init() {
    if (!isLiveChatEnabled) return;
    if (!buttonId || !chatEndpoint || !deploymentId || !organizationId) return console.error("1 or more chat settings are missing:", liveChatConfig);

    // Updating live chat button state onload
    if (!window._laq) window._laq = [];

    window._laq.push(function () {
        const loadingButton = queryFirst('.live-agent-loading-button');
        const onlineButtons = queryAll('.live-agent-online-button');
        const offlineButtons = queryAll('.live-agent-offline-button');

        const observer = new MutationObserver((mutations, self) => {
            // If the style attribute is updated, we can hide the loading message
            if (mutations.some(mutation => mutation.attributeName === 'style')) {
                self.disconnect();
                addClass(loadingButton, 'd-none');
            }
        });

        // Attach the observer to all buttons
        [].concat(onlineButtons, offlineButtons).forEach(element => {
            observer.observe(element, { childList: false, subtree: false, attributes: true });
        });

        onlineButtons.forEach(node => {
            liveagent.showWhenOnline(buttonId, node);
            node.addEventListener('click', startChat);
        });

        offlineButtons.forEach(node => {
            liveagent.showWhenOffline(buttonId, node);
        });
    });

    liveagent.init(chatEndpoint, deploymentId, organizationId);
}

module.exports = {
    initLiveChat: init
};
