'use strict';

import { queryFirst, queryAll, addClass, removeClass, hasClass, debounce } from '../domUtil';
import { getNestedValue } from '../util';
const mainVideoClass = 'video-component-main';
const mobileVideoClass = 'video-component-mobile';

/**
 * Shows or hides the play button depending on whether or not a video is playing/visible.
 * @param {Array} allVideos Array containing all video elements within a video component (mobile and desktop)
 * @param {HTMLElement} playButton Element representing the play button icon for a video component
 * @param {boolean} ignoreTime Whether or not to ignore video.currentTime when determining if the video is playing (sometimes a race condition occurs on initial play and loop play)
 */
function updatePlayButton(allVideos, playButton, ignoreTime = false) {
    const playButtonShowMain = 'd-md-block';
    const playButtonShowMobile = 'd-block';
    const playButtonHideMain = 'd-md-none';
    const playButtonHideMobile = 'd-none';

    // reset
    [playButtonShowMain, playButtonShowMobile, playButtonHideMain, playButtonHideMobile].forEach(btnClass => {
        removeClass(playButton, btnClass);
    });

    allVideos.forEach(video => {
        const isMobileVideo = hasClass(video, mobileVideoClass);
        const videoIsPlaying = (ignoreTime || video.currentTime > 0) && !video.paused && !video.ended;

        // if there is no mobile specific video, then the mobile play button should mimic the desktop behavior
        if (isMobileVideo || allVideos.length === 1) {
            addClass(playButton, videoIsPlaying ? playButtonHideMobile : playButtonShowMobile);
        } else {
            addClass(playButton, videoIsPlaying ? playButtonHideMain : playButtonShowMain);
        }
    });
}

const initVideos = videoComponents => {
    if (!(videoComponents || []).length) {
        return;
    }
    videoComponents.forEach(videoComponent => {
        const playVideoElement = queryFirst('.play-video', videoComponent);
        const mainVideo = queryFirst(`.${mainVideoClass}`, videoComponent);
        const mobileVideo = queryFirst(`.${mobileVideoClass}`, videoComponent);
        const allVideos = [mainVideo, mobileVideo].filter(Boolean);

        updatePlayButton(allVideos, playVideoElement);

        allVideos.forEach(video => {
            const isLoop = video.hasAttribute('loop');
            let touchStart, touchEnd;

            if (video.hasAttribute('autoplay')) {
                updatePlayButton(allVideos, playVideoElement);

                const module = video.closest('.content-tile');
                if (!hasClass(module, 'link-entire-module')) {
                    video.controls = true;
                }
            }

            // play and pause on click of video on mobile
            if ('ontouchstart' in window) {
                video.addEventListener('touchstart', e => {
                    touchStart = getNestedValue(e, 'touches[0].clientY');
                });

                video.addEventListener('touchend', e => {
                    touchEnd = getNestedValue(e, 'changedTouches[0].clientY');

                    // Only continue with the tap action if the user TAPPED -- not tapped and dragged (to scroll)
                    if (touchStart && touchEnd && (Math.abs(touchEnd - touchStart) < 2)) {
                        const rect = video.getBoundingClientRect();
                        const ctrlHeight = 30;
                        const y = touchEnd - rect.top; // y-coordinate of the user's tap within the video

                        // only play/pause the video if the user isn't trying to use player controls
                        // safari has controls on the top and bottom of the player
                        if (y <= rect.height - ctrlHeight && y > ctrlHeight) {
                            if (video.paused) {
                                video.play();
                            } else {
                                video.pause();
                            }
                        }
                    }
                });
            }

            // play video on click of play-video button and hide play-video button
            playVideoElement.addEventListener('click', () => {
                const videoIsVisible = window.getComputedStyle(video).display !== 'none';
                if (video && videoIsVisible) {
                    video.play();
                }
            });

            // called on first video play
            video.addEventListener('play', () => {
                updatePlayButton(allVideos, playVideoElement, true);
            });

            // show play-video button if paused
            video.addEventListener('pause', () => {
                updatePlayButton(allVideos, playVideoElement);
            });

            // hide play-video button while playing video -- also called on video loop and pause resume
            video.addEventListener('playing', () => {
                updatePlayButton(allVideos, playVideoElement, true);

                const module = video.closest('.content-tile');
                if (!hasClass(module, 'link-entire-module')) {
                    video.controls = true;
                }
                if (!isLoop) {
                    video.focus({ preventScroll: true });
                }
            });

            // show play-video button after end of video play
            video.addEventListener('ended', () => {
                if (!isLoop) {
                    updatePlayButton(allVideos, playVideoElement);
                    video.controls = false;
                    video.autoplay = false;
                    video.load(); // re-show poster image
                }
            });
        });

        // Control video playback on window resize
        // if a video is no longer visible but is playing, pause it
        // if a video was previously paused by this listener and is now visible again, resume
        window.addEventListener('resize', debounce(() => {
            const wasPlayingClass = 'resize-paused';
            allVideos.forEach(video => {
                const videoIsVisible = window.getComputedStyle(video).display !== 'none';
                const videoIsPlaying = video.currentTime > 0 && !video.paused && !video.ended;

                if (!videoIsVisible && videoIsPlaying) {
                    addClass(video, wasPlayingClass);
                    video.pause();
                }

                if (videoIsVisible && hasClass(video, wasPlayingClass)) {
                    removeClass(video, wasPlayingClass);
                    video.play();
                }
            });
        }));
    });
};

$('body').on('product:afterAttributeChange', function () {
    initVideos(queryAll('.video-component'));
});

initVideos(queryAll('.video-component'));
