'use strict';

import { queryFirst } from '../domUtil';
import {
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
    getJSON
} from '../util';
import { PREFERRED_STORE_ID } from '../constants';

/**
 * Success event handler for setting preferred stores
 */
function handleSetPreferredStoreResults() {
    removeItemFromLocalStorage(PREFERRED_STORE_ID);
}

/**
 * Shows the merged basket dialog if applicable
 */
function showMergedBasketDialog() {
    const dialog = $('.merge-baskets-dialog');

    if (!dialog.length) return;

    dialog.find('.view-cart-btn').on('click', () => {
        dialog.modal('hide');
    });

    dialog.modal('show');
}

/**
 * Initializes post login actions
 */
function initPostLoginActions() {
    const checkUserLoggedInNode = document.getElementById('notifyEmail');
    const setPreferredStoreNode = queryFirst('.set-preferred-store');

    showMergedBasketDialog();

    if (checkUserLoggedInNode && setPreferredStoreNode) {
        // In case of loggedIn user, hidden input field value will be set to email address of the user
        if (!checkUserLoggedInNode.value) {
            return;
        }

        const { actionUrl } = setPreferredStoreNode.dataset;
        const storeIdToBeUpdated = getItemFromLocalStorage(PREFERRED_STORE_ID);

        if (!storeIdToBeUpdated) {
            return;
        }

        getJSON(
            actionUrl,
            'POST',
            { storeID: storeIdToBeUpdated },
            handleSetPreferredStoreResults
        );
    }
}

export default initPostLoginActions;
