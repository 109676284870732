'use strict';
import cart, { updateApproachingDiscounts } from '../cart/cart';
import { formatMessage, appendToUrl } from '../util';
import { queryFirst, removeClass, addClass, emptyCart, hasClass } from '../domUtil';
import { trackCart } from './etmc';

const $body = $('body');
const $miniCart = $('.minicart');
const $miniCartDialog = $('#minicart');
const $miniCartContent = $miniCartDialog.find('.mini-cart-container');
const $miniCartQuantity = $('.minicart-quantity');
const $miniCartLink = $miniCart.find('.minicart-link');
const mql = window.matchMedia('(min-width: 1024px)');

let isMiniCartUpdating = true;

/**
 * Updates mini cart title and close button with latest quantity.
 */
const updateTitle = () => {
    const labelText = formatMessage($miniCartContent.data('title-label'), $miniCartQuantity.text());
    const closeText = formatMessage($miniCartContent.data('close-label'), labelText);

    const labelElem = queryFirst('#minicart-label');
    const closeBtn = queryFirst('#minicart .close-current-modal');

    if (labelElem) {
        labelElem.textContent = labelText;
    }

    if (closeBtn) {
        closeBtn.ariaLabel = closeText;
    }
};

const updateMiniCartIcon = () => {
    const countVal = parseInt($miniCartQuantity.text(), 10);
    const iconToteActive = queryFirst('.icon-tote-active');
    const iconTote = queryFirst('.icon-tote');
    const hiddenClass = 'd-none';

    $miniCartQuantity.text(countVal);

    if (countVal) {
        removeClass(iconToteActive, hiddenClass);

        if (!hasClass(iconTote, hiddenClass)) {
            addClass(iconTote, hiddenClass);
        }
    } else {
        removeClass(iconTote, hiddenClass);

        if (!hasClass(iconToteActive, hiddenClass)) {
            addClass(iconToteActive, hiddenClass);
        }
    }
};
/**
 * Displays mini cart error message
 * @param {string} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = `<p class="cart-error-message">${message}</p>`;
    $('.cart-error')
        .empty()
        .append(errorHtml);
}

/**
 * Initializes the event handlers
 */
function init() {
    cart();

    $miniCart.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $miniCart.find('.minicart-quantity').text(count.quantityTotal);
            updateMiniCartIcon();
            updateTitle();
            $miniCart.find('.minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $miniCartLink.on('touchstart click', function (e) {
        const url = $miniCart.data('action-url');
        const count = parseInt($miniCart.find('.minicart-quantity').text(), 10);
        const emptyCartContent = queryFirst('.empty-cart-content');
        if (this.dataset.sflEnabled === 'true' && !count) {
            e.stopImmediatePropagation();
            e.preventDefault();
            location.href = this.dataset.actionUrl;
        }
        else {
            if (!$miniCartDialog.hasClass('.show')) {
                if (isMiniCartUpdating && !$miniCartDialog.attr('aria-busy')) {
                    $miniCartDialog.attr('aria-busy', true);
                    $miniCartDialog
                        .find('.modal-dialog')
                        .spinner()
                        .start();
                    $.get(url, function (data) {
                        if ($(data).find('.product-line-item').length > 0) {
                            $miniCartContent.empty().append(data);
                        } else {
                            $miniCartContent.empty().append(data);
                            $miniCart.trigger('count:update', [
                                {
                                    quantityTotal: 0,
                                    minicartCountOfItems: $('.minicart-link').attr('data-zero-count')
                                }
                            ]);
                            removeClass(emptyCartContent, 'd-none');
                            $miniCartContent.find('.cart.container').addClass('d-none');
                            updateTitle();
                        }
                        $miniCartContent.closest('.modal-content').scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                        isMiniCartUpdating = false;
                        $miniCartDialog.removeAttr('aria-busy');
                        $.spinner().stop();
                    }).fail(function () {
                        $miniCartDialog.removeAttr('aria-busy');
                        $.spinner().stop();
                    });
                }
            }
        }
        const minicart = queryFirst('#minicart .cart');
        if (count === 0) {
            removeClass(emptyCartContent, 'd-none');
            if (minicart) {
                minicart.innerHTML = '';
            }
        } else {
            addClass(emptyCartContent, 'd-none');
        }

        const windowTest = w => {
            if (w.matches) {
                return;
            }
            if (hasClass(queryFirst('.main-menu'), 'in')) {
                queryFirst('.navbar-toggler').click();
            }
        };
        windowTest(mql);
    });
    $body.on('cart:update', () => {
        isMiniCartUpdating = true;
        updateTitle();
        updateMiniCartIcon();
    });

    // After a product is added to cart
    $body.on('product:afterAddToCart', (e, data) => {
        isMiniCartUpdating = true;
        updateTitle();

        // update Marketing Cloud Analytics trackCart event
        const { marketingCloudAnalyticsData: mcData } = data;
        if (mcData) trackCart(mcData);

        if (!queryFirst('.cart-container') && data && !data.error) {
            // Use jQuery so GA binding doesn't fire
            $('.minicart-link').click();
        }
    });

    $body.on('click', '.remove-product-btn', function (e) {
        e.preventDefault();

        let uuid = $(this).data('uuid');
        let url = $(this).data('action');
        const { name } = this.dataset;
        var urlParams = {
            pid: $(this).data('pid'),
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);
        $miniCartContent
            .find('.modal-dialog')
            .spinner()
            .start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length) {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    if (!data.basket.inStoreProductLineItems.length) {
                        const hiddenClass = 'd-none';
                        addClass(queryFirst('.mini-cart-store-title'), hiddenClass);
                        addClass(queryFirst('.cart-pickup-products'), hiddenClass);
                    }
                }
                $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                });
                const subTotalElement = queryFirst('.minicart-footer .price-value');
                const { totals, valid, numItems } = data.basket;
                if (subTotalElement) {
                    subTotalElement.textContent = totals.subTotal;
                }

                if (!valid.error) {
                    const cartError = queryFirst('.cart-error');

                    if (cartError) cartError.textContent = '';
                } else {
                    createErrorNotification(valid.message);
                }

                if (numItems === 0) {
                    const emptyCartContent = queryFirst('.empty-cart-content');
                    removeClass(emptyCartContent, 'd-none');
                    $miniCartContent.find('.cart.container').addClass('d-none');
                }
                const { bonusLineItems, approachingDiscounts } = data.basket;
                if (bonusLineItems && bonusLineItems.maxPids) {
                    const quantityEl = queryFirst('.gwp-option-bonus .item-quantity .attribute-value', $miniCart[0]);
                    const cartGwpQuantityEl = queryFirst('.cart-container .item-description');
                    if (quantityEl) {
                        quantityEl.textContent = bonusLineItems.maxPids;
                    }

                    if (cartGwpQuantityEl) {
                        cartGwpQuantityEl.textContent = cartGwpQuantityEl.textContent.replace(/\d/, bonusLineItems.maxPids);
                    }
                }
                $miniCartQuantity.empty().append(data.basket.numItems);
                updateMiniCartIcon();

                $body.trigger('cart:update');
                $('.cart-container, .mini-cart-container').trigger('cart:updateTotals', data);
                $body.trigger('removeFromMinicart', name);
                updateApproachingDiscounts(approachingDiscounts);

                // update Marketing Cloud Analytics trackCart event
                trackCart(data.mcAnalyticsArray);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
    });

    $miniCartDialog.on('shown.bs.modal', function () {
        addClass(queryFirst('body'), 'modal-open');
    });
}

export default init;
