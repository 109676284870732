/**
 * OOTB code. Added animations which are under LP customization section.
 */

'use strict';

const { queryFirst, addClass, removeClass } = require('../domUtil');
const { formatPhoneNumber } = require('../util');
const { setUserInfo } = require('./etmc');
const invalidClass = 'is-invalid';

const phoneNumberEl = queryFirst('.mobile-number-signup');
const $body = $('body');

/**
 * Clear fields on popup closing
 */
function onSignUpModalClose() {
    $body.on('hidden.bs.modal', '.success-modal', function () {
        phoneNumberEl.value = '';
        queryFirst('.email-signup').value = '';
    });
}
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    // LP customization
    $.spinner().stop();
    const invalidFeedback = queryFirst('footer .footer-email-signup .invalid-feedback');
    if (data.success) {
        $('#success-modal').modal('show');

        $('#sucess-modal').on('shown.bs.modal', () => {
            $('#success-modal .close').focus();
        });

        $('#success-modal').keydown(function (e) {
            if (e.keyCode === 9) {
                e.preventDefault();
            }
        });

        removeClass(queryFirst('footer .email-signup'), invalidClass);
    } else {
        invalidFeedback.textContent = data.msg;
        addClass(queryFirst('footer .email-signup'), invalidClass);
        $body.trigger('subscribe:fail');
    }
    button.disabled = false;
    onSignUpModalClose();
    // LP customization
}

module.exports = function () {
    $('.footer-email-signup').on('submit', function (e) {
        e.preventDefault();
        const button = queryFirst('.subscribe-email', this);
        const url = button.dataset.href;
        const emailInput = queryFirst('.email-signup');
        const emailId = emailInput.value;
        const mobileNum = phoneNumberEl.value;
        if (!emailId) {
            const invalidFeedback = queryFirst('footer .invalid-feedback');
            invalidFeedback.textContent = emailInput.dataset.missingError;
            addClass(emailInput, invalidClass);
            $body.trigger('subscribe:fail');
            return;
        }
        removeClass(emailInput, invalidClass);
        $.spinner().start();
        button.disabled = true;
        $(button).attr('aria-disabled', "button disabled");
        $('body').trigger('emailSubmit');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId,
                source: 'footerNewsletterSignup',
                mobileNum
            },
            success: function (data) {
                displayMessage(data, button);
                $(button).removeAttr('aria-disabled');
                // update Marketing Cloud Analytics trackCart event
                setUserInfo(data.updatedUserInfo);
                if (data.error) return;
                $body.trigger('subscribe:success');
            },
            error: function (err) {
                displayMessage(err, button);
                $body.trigger('subscribe:fail');
            }
        });
    });

    if (phoneNumberEl) {
        phoneNumberEl.addEventListener('keyup', () => {
            formatPhoneNumber(phoneNumberEl);
        });
    }
};
