/**
 * OOTB SFRA JS code
 */

import base from '../product/base';
import { queryFirst, addClass, removeClass, queryAll, remove, emptyCart, hasClass, scrollTo, throttle } from '../domUtil';
import { appendToUrl, getJSON, formatMessage, getNestedValue } from '../util';
import { trackCart } from '../components/etmc';
import { HIDDEN_CLASS, INVALID_CLASS } from '../constants';
import { checkAccountAndFetchDetail } from 'bolt/bolt/account';
const { cart: cartTemplates, approachingDiscounts: approachingDiscountsTemplates, promo: promoTemplates, emptySFL: emptySFLTemplates } = require('../templates');
const checkoutContainer = queryFirst('.data-checkout-stage');
const formValidation = require('base/components/formValidation');
const displayFlex = 'd-flex';
const selectedTabClass = 'sfl-tabs-selected';
const $body = $('body');
const loyaltyVoucherApplied = 'applied-reward-bg';
const lpRegisteredUserEmailEl = queryFirst('.registered-user-email');
const lpRegisteredUserEmail = lpRegisteredUserEmailEl ? lpRegisteredUserEmailEl.value : '';
const boltCheckoutAccountExistEl = queryFirst('.check-bolt-account-exist');

/**
 * Displays cart error message
 * @param {string} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = formatMessage(cartTemplates.errorMessage, message);
    $('.cart-container .cart-error')
        .empty()
        .append(errorHtml);
}

/**
 * Update the GWP HTML if contained in an ajax response
 * @param {Object} data The response data from an ajax call containing a 'gwpProductsHtml' attribute
 * @param {string} data.gwpProductsHtml The GWP HTML to insert
 */
function updateGWP(data) {
    if ('gwpProductsHtml' in data) {
        $('.gwp-container-js, #checkout-main .gwp-container').html(data.gwpProductsHtml);
    }
}

/**
 * enable or disables the checkout button
 */
function enableOrDisableCheckoutButton() {
    const checkoutButtonElement = queryFirst('.checkout-btn');

    if (!checkoutButtonElement) return;

    const expressCheckoutSection = queryFirst('.express-checkout-actions');
    const gwpProductsLength = queryAll('.gwp-option-bonus.product-info').length;
    const errorMessage = queryFirst('.cart-container .cart-error .cart-error-message');
    const cartWithSFLElement = queryFirst('.js-cart-container .js-cart-with-items');
    const oosProduct = queryFirst('.line-item-attributes.out-of-stock', (cartWithSFLElement || document));
    const chooseGwpMessage = queryFirst('.gwp-message');
    const monogramRestrictionLength = queryAll('.js-monogram-restriction').length;
    const numLowInventoryWarnings = queryAll('.low-inventory-warning').length;

    if (gwpProductsLength || errorMessage || oosProduct || monogramRestrictionLength || numLowInventoryWarnings) {
        addClass(checkoutButtonElement, 'disabled');
        checkoutButtonElement.removeAttribute('href');
        addClass(expressCheckoutSection, HIDDEN_CLASS);
    } else {
        removeClass(checkoutButtonElement, 'disabled');
        checkoutButtonElement.setAttribute('href', checkoutButtonElement.dataset.href);
        const grandTotalVal = queryFirst('.grand-total-value.grand-total');
        const cartPage = queryFirst('.cart-page');

        if (grandTotalVal && cartPage && grandTotalVal.textContent === cartPage.dataset.zeroTotal) {
            addClass(expressCheckoutSection, HIDDEN_CLASS);
        } else {
            removeClass(expressCheckoutSection, HIDDEN_CLASS);
        }
    }

    if (chooseGwpMessage) {
        if (gwpProductsLength && !errorMessage) {
            removeClass(chooseGwpMessage, HIDDEN_CLASS);
        } else {
            addClass(chooseGwpMessage, HIDDEN_CLASS);
        }
    }

    if (!hasClass(checkoutButtonElement, 'disabled')) {
        const boltCheckoutCtaEl = queryFirst('.bolt-checkout-wrapper');
        const { isBoltEnable, isBoltUserLoggedIn } = checkoutButtonElement.dataset;

        addClass(boltCheckoutCtaEl, HIDDEN_CLASS);

        if (isBoltEnable === 'true' && isBoltUserLoggedIn !== 'true' && lpRegisteredUserEmail && boltCheckoutAccountExistEl) {
            const checkBoltAccountUrl = boltCheckoutAccountExistEl.value + '=' + encodeURIComponent(lpRegisteredUserEmail);
            $.ajax({
                url: checkBoltAccountUrl,
                method: 'GET',
                success(data) {
                    if (data && data.has_bolt_account) {
                        removeClass(boltCheckoutCtaEl, HIDDEN_CLASS);
                    }
                },
                error: function () { }
            });
        }
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            createErrorNotification(data.valid.message);
        } else {
            emptyCart(data.resources.emptyCartMsg);
            $('.number-of-items').empty();
            $('.minicart-quantity')
                .empty()
                .append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }
    } else {
        const cartErrors = queryFirst('.cart-container .cart-error');
        if (cartErrors) {
            cartErrors.textContent = '';
        }
    }
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    const couponErrorMessage = queryFirst('.coupon-error-message');
    if (couponErrorMessage) {
        couponErrorMessage.textContent = '';
    }
    enableOrDisableCheckoutButton();
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    const { giftWrapQuantity, giftWrapTotalPrice, totals, giftWrapISPUTotalPrice, giftWrapISPUQuantity, numItems } = data;
    const giftWrapContainer = queryFirst('.gift-wrap-container');
    const giftWrapISPUContainer = queryFirst('.gift-wrap-ispu-container');
    const nonCouponContainer = queryFirst('.non-coupon-price-container');
    if (giftWrapContainer) {
        if (giftWrapQuantity > 0) {
            queryFirst('.gift-wrap-quantity', giftWrapContainer).textContent = giftWrapQuantity.toString();
            queryFirst('.gift-wrap-price', giftWrapContainer).textContent = giftWrapTotalPrice.toString();
            if (!hasClass(giftWrapContainer, displayFlex)) {
                removeClass(giftWrapContainer, HIDDEN_CLASS);
                addClass(giftWrapContainer, displayFlex);
            }
        } else {
            removeClass(giftWrapContainer, displayFlex);
            addClass(giftWrapContainer, HIDDEN_CLASS);
        }
    }
    if (giftWrapISPUContainer) {
        if (giftWrapISPUQuantity > 0) {
            const { freeprice } = giftWrapISPUContainer.dataset;
            queryFirst('.gift-wrap-ispu-quantity', giftWrapISPUContainer).textContent = giftWrapISPUQuantity.toString();
            queryFirst('.gift-wrap-ispu-price', giftWrapISPUContainer).textContent = giftWrapISPUTotalPrice.totalPrice == 0 ? freeprice : giftWrapISPUTotalPrice.formattedPrice.toString();
            if (!hasClass(giftWrapISPUContainer, displayFlex)) {
                removeClass(giftWrapISPUContainer, HIDDEN_CLASS);
                addClass(giftWrapISPUContainer, displayFlex);
            }
        } else {
            removeClass(giftWrapISPUContainer, displayFlex);
            addClass(giftWrapISPUContainer, HIDDEN_CLASS);
        }
    }
    const cartWithSFLCountTotal = queryFirst('.cart-items-count .item-count-tote');
    if (cartWithSFLCountTotal) {
        cartWithSFLCountTotal.textContent = numItems;
    }
    $('.number-of-items')
        .empty()
        .append(data.resources.numberOfItems);
    $('.grand-total')
        .empty()
        .append(data.totals.subTotal);
    $('.sub-total')
        .empty()
        .append(data.totals.totalAmount);
    $('.tax-total')
        .empty()
        .append(data.totals.totalTax);
    $('.minicart-quantity')
        .empty()
        .append(data.numItems);
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total')
            .empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    const cartErrors = queryAll('.cart-error');
    if (!data.valid.error) {
        addClass(cartErrors, HIDDEN_CLASS);
    } else {
        removeClass(cartErrors, HIDDEN_CLASS);
    }

    if (nonCouponContainer) {
        let nonCouponPromotionsHTML = '';
        if (totals.nonCouponBasedAdjustments && totals.nonCouponBasedAdjustments.length) {
            totals.nonCouponBasedAdjustments.forEach(function (couponItem) {
                nonCouponPromotionsHTML += formatMessage(approachingDiscountsTemplates.nonCouponBasedAdjustmentTemplate, couponItem.promoTitle, couponItem.discountPrice, couponItem.callOutMsg);
            });
        }
        nonCouponContainer.innerHTML = nonCouponPromotionsHTML;
    }

    data.items.forEach(function (item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID)
                .empty()
                .append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID)
                .empty()
                .append(item.priceTotal.renderedPrice);
        }
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 * @param {Object} approachingDiscountsLabels - Approaching discount label description (contains show more / show less)
 */
function updateApproachingDiscounts(approachingDiscounts, approachingDiscountsLabels) {
    let $approachingDiscountsEl = $('.approaching-discounts');
    if ((approachingDiscounts || []).length) {
        let html = '';
        let item = null;
        if (!$approachingDiscountsEl.length) {
            $('.discounts-section').append(approachingDiscountsTemplates.discountContainerTemplate);
            $approachingDiscountsEl = $('.approaching-discounts');
        } else {
            $approachingDiscountsEl.empty();
        }
        if (approachingDiscounts.length === 1) {
            item = approachingDiscounts[0].discountMsg;
            html += formatMessage(approachingDiscountsTemplates.singleDiscountTemplate, item);
            $approachingDiscountsEl.removeClass('multiple-approaching-discounts');
        } else if (approachingDiscounts.length > 1 && approachingDiscountsLabels) {
            item = approachingDiscounts[0].discountMsg;
            html += formatMessage(approachingDiscountsTemplates.singleDiscountTemplate, item);
            const remainingDiscounts = approachingDiscounts.slice(1, approachingDiscounts.length);
            let remainingDiscountsHtml = '';
            remainingDiscounts.forEach(function (discount) {
                remainingDiscountsHtml += formatMessage(approachingDiscountsTemplates.singleDiscountTemplate, discount.discountMsg);
            });
            html += formatMessage(approachingDiscountsTemplates.multipleDiscountsTemplate, remainingDiscountsHtml, approachingDiscountsLabels.showMore, approachingDiscountsLabels.showLess);
            $approachingDiscountsEl.addClass('multiple-approaching-discounts');
        }
        $approachingDiscountsEl.append(html);
    } else {
        $approachingDiscountsEl.remove();
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += `<p class="line-item-attributes ${!lineItem.available ? 'out-of-stock' : ''}"> ${message} </p>`;
            });
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 * @param {boolean} giftWrapAvailable - flag to check gift wrap applicability
 */
function updateProductDetails(data, uuid, giftWrapAvailable) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            $(colorSelector)
                .find('.line-item-text')
                .text(colorAttr.displayValue);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            $(sizeSelector)
                .find('.line-item-text')
                .text(sizeAttr.displayValue);
        }

        const { newProductId } = data || {};
        if (newProductId) {
            const skuElement = queryFirst(`.sku-${uuid}`);
            const skuLabel = skuElement && skuElement.dataset.skuLabel;

            if (skuElement && skuLabel) {
                skuElement.textContent = `${skuLabel} ${newProductId}`;
            }
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('srcset', lineItem.images.small[0].srcset);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).attr('data-value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"], .move-to-wishlist-' + uuid + ' a, .gift-wrap-input[data-uuid="' + uuid + '"], .btn-in-store-pickup[data-uuid="' + uuid + '"], .home-delivery-link[data-uuid="' + uuid + '"], .quantity[data-uuid="' + uuid + '"], .cart-product-line-item[data-uuid="' + uuid + '"]').attr(
        'data-pid',
        data.newProductId
    );
    const cartStoreLinksSelector = '.product-info.uuid-' + uuid + ' .cart-store-links';
    if (giftWrapAvailable === 'false') {
        $('.gift-wrap-input[data-uuid="' + uuid + '"]').prop('checked', false);
        $(cartStoreLinksSelector + ' form').addClass(HIDDEN_CLASS);
        $(cartStoreLinksSelector + ' .seperator').addClass(HIDDEN_CLASS);
    } else if ($(cartStoreLinksSelector).closest('.cart-pickup-products').length === 0) {
        $(cartStoreLinksSelector + ' form').removeClass(HIDDEN_CLASS);
        $(cartStoreLinksSelector + ' .seperator').removeClass(HIDDEN_CLASS);
    }

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }

    if (lineItem.isGiftCard) {
        const { giftCardAmount } = lineItem;

        const giftCardHiddenAmountField = queryFirst(`.product-info.uuid-${uuid} .gift-card-amount`);
        if (giftCardHiddenAmountField) {
            giftCardHiddenAmountField.value = giftCardAmount;
        }

        queryAll(`.move-to-wishlist-${uuid} .move`).forEach(el => {
            el.dataset.giftCardAmount = giftCardAmount;
        });
        queryFirst(`.remove-product-${uuid}`).dataset.giftCardAmount = giftCardAmount;
    }
}

/**
 * This method sets the data of line item to be deleted in the delete confirmation modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid, tealium = '{}') {
    const deleteConfirmBtn = queryFirst('.cart-page .cart-delete-confirmation-btn');
    deleteConfirmBtn.dataset.pid = productID;
    deleteConfirmBtn.dataset.action = actionUrl;
    deleteConfirmBtn.dataset.uuid = uuid;
    deleteConfirmBtn.dataset.name = productName;
    deleteConfirmBtn.dataset.tealium = tealium;
}

/**
 * Lilly Customization changes start
 */

/**
 * Update promo code details
 * @param {array} discounts - updated discounts data
 * @param {Object} shippingMethod - Shipping method detail object
 */
function updatePromoCodeDetails(discounts, shippingMethod) {
    const promotionsListContainer = queryFirst('.promo-code-section');
    const cartShippingMethodContainer = queryFirst('.cart-shipping-method');
    const checkoutShippingMethodContainer = queryFirst('.shipping-method-price-container');
    const checkoutShippingMethodTitle = queryFirst('.shipping-method-title');
    let promotionsHtml = '';
    if (discounts && discounts.length) {
        const promoTemplate = document.getElementById('promo-code-details-template').cloneNode(true);
        const promoContainer = queryFirst('.price-container', promoTemplate);
        const promoText = queryFirst('.price-text', promoContainer);
        const promoTitle = promoText.dataset.promoTitle;
        const promoValue = queryFirst('.price-value', promoContainer);
        const description = queryFirst('.promo-description', promoContainer);
        discounts.forEach(eachDiscount => {
            if (eachDiscount.type === 'coupon' && eachDiscount.applied) {
                const couponData = eachDiscount.relationship;
                promoText.textContent = `${promoTitle} ${eachDiscount.couponCode}`;
                if (couponData && couponData.length) {
                    promoValue.textContent = eachDiscount.totalPrice;
                    description.textContent = couponData[0].name;
                } else {
                    promoValue.textContent = '';
                    description.textContent = '';
                }
                promotionsHtml += promoTemplate.innerHTML;
            }
        });
        if (cartShippingMethodContainer) {
            if (shippingMethod && shippingMethod.isDiscounted) {
                cartShippingMethodContainer.innerHTML = formatMessage(promoTemplates.shippingMethod, shippingMethod.shippingLabel, shippingMethod.displayName, shippingMethod.shippingCost, shippingMethod.estimatedArrivalTime);
            } else {
                cartShippingMethodContainer.innerHTML = '';
            }
        }

        if (checkoutShippingMethodContainer) {
            if (shippingMethod && shippingMethod.isDiscounted) {
                checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutDiscountShipping, shippingMethod.shippingCost, shippingMethod.adjustedShippingCost);
            } else {
                checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutNormalShipping, shippingMethod.shippingCost);
            }
        }
    } else if (cartShippingMethodContainer) {
        cartShippingMethodContainer.innerHTML = '';
    } else if (checkoutShippingMethodContainer) {
        checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutNormalShipping, shippingMethod.shippingCost);
    }
    if (checkoutShippingMethodTitle) {
        checkoutShippingMethodTitle.innerHTML = shippingMethod.displayName;
    }
    if (promotionsListContainer) {
        promotionsListContainer.innerHTML = promotionsHtml;
    }
}

/**
 * This function updates Form Object for Gift card values
 * @param {Object} formObject - object to edit to add gift card amount
 * @param {HTMLElement} formEl - form node to fetch gift card amount
 */
function updateFormObjectForGiftCard(formObject, formEl) {
    const amountField = queryFirst('.gift-card-amount', formEl);

    if (amountField) {
        formObject.giftCardAmount = amountField.value;
    }
}

/**
 * updates the monogram elements on cart page
 * @param {Object} data - update quantity and remove from cart ajax response
 */
function updateMonogramDetails(data) {
    const { monogramPrice, monogramQuantity } = data;
    const monogramContainer = queryFirst('.cart-totals .monogram-container');
    if (monogramContainer) {
        queryFirst('.price-value', monogramContainer).textContent = monogramPrice;

        queryFirst('.monogram-quantity', monogramContainer).textContent = monogramQuantity;
        if (!monogramQuantity) {
            addClass(monogramContainer, 'd-none');
        } else {
            removeClass(monogramContainer, 'd-none');
        }
    }
}

/**
 * toggle cart summary section in mobile and when tab switched from SFL to MY Tote and vice-versa
 */
function hideShowSummarySection() {
    const sflTabElement = queryFirst('.save-for-later-tab');
    const sflCartElement = queryFirst('.cart-sfl-summary');
    const isCartEmpty = queryFirst('.js-cart-empty');
    const isSFLSelected = hasClass(sflTabElement, selectedTabClass);
    if (window.matchMedia('(max-width: 768px)').matches) {
        if (isSFLSelected) {
            addClass(sflCartElement, HIDDEN_CLASS);
        } else if (!isCartEmpty || hasClass(isCartEmpty, HIDDEN_CLASS)) {
            removeClass(sflCartElement, HIDDEN_CLASS);
        }
    }
    else if (!isCartEmpty || hasClass(isCartEmpty, HIDDEN_CLASS)) {
        removeClass(sflCartElement, HIDDEN_CLASS);
    }
}
/**
 * updates the Save for later on cart page
 * @param {Object} data - add product to tote from SFL cart ajax response
 */
function updateSaveForLaterDetails(data) {
    const { saveForLaterHtml, sflCountMsg, count, isLoggedIn } = data;
    const sflProductElement = queryFirst('.sfl-cart-products');
    const sflTitleCount = queryFirst('.number-of-items-sfl');
    const signInSFLBottomEl = queryFirst('.sfl-login-wrapper');
    const moveAllToCartCTA = queryFirst('.move-all-items-to-tote');

    if (sflProductElement) {
        sflProductElement.innerHTML = saveForLaterHtml;
    }

    if (sflTitleCount) {
        sflTitleCount.textContent = sflCountMsg;
    }
    if (count === 0) {
        addClass([signInSFLBottomEl, moveAllToCartCTA], HIDDEN_CLASS);
        hideShowSummarySection();
        if (isLoggedIn) {
            const loginSFL = queryFirst('.sfl-login-btn');
            addClass(loginSFL, HIDDEN_CLASS);
        }
    }
}
/**
 * Updates the loyalty rewards and offers in cart and checkout page
 * @param {Object} summarySection - Rewards or Offers Section
 * @param {Object} couponData - data of the rewards or offers
 * @param {number} couponCount - count of redeemed rewards/offers
 * @param {string} couponPrice - total price of redeemed rewards/offers
 * @param {string} ctaClass - wrapper class for apply and remove CTAs in offers and rewards
 */
function updateLoyaltyRewardsOffers(summarySection, couponData, couponCount, couponPrice, ctaClass) {
    if (couponCount) {
        removeClass(summarySection, HIDDEN_CLASS);
        const redeemedCouponCountEl = queryFirst('.loyalty-cart-reward-quantity', summarySection);
        const redeemedCouponPriceEl = queryFirst('.loyalty-cart-reward-price', summarySection);
        redeemedCouponCountEl.textContent = couponCount;
        redeemedCouponPriceEl.textContent = couponPrice;
        queryAll(ctaClass).forEach((el) => {
            const { voucherCode } = el.dataset;
            const removeCouponCta = queryFirst('.remove-voucher-tote-cta', el);
            const applyCouponCta = queryFirst('.apply-voucher-tote-cta', el);
            const couponCardEl = el.closest('.voucher-card-content');
            const matchingCoupon = couponData.find(voucher => voucher.code === voucherCode);
            if (matchingCoupon) {
                const { applied, UUID } = matchingCoupon;
                if (applied) {
                    removeClass(removeCouponCta, HIDDEN_CLASS);
                    addClass(applyCouponCta, HIDDEN_CLASS);
                    addClass(couponCardEl, loyaltyVoucherApplied);
                    el.dataset.uuid = UUID;
                } else {
                    addClass(removeCouponCta, HIDDEN_CLASS);
                    removeClass(applyCouponCta, HIDDEN_CLASS);
                    removeClass(couponCardEl, loyaltyVoucherApplied);
                }
            }
        });
    } else {
        queryAll(ctaClass).forEach((el) => {
            const removeCouponCta = queryFirst('.remove-voucher-tote-cta', el);
            const applyCouponCta = queryFirst('.apply-voucher-tote-cta', el);
            const couponCardEl = el.closest('.voucher-card-content');
            addClass(removeCouponCta, HIDDEN_CLASS);
            removeClass(applyCouponCta, HIDDEN_CLASS);
            removeClass(couponCardEl, loyaltyVoucherApplied);
        });
        addClass(summarySection, HIDDEN_CLASS);
    }
}

/**
 * updates the loyalty vouchers on cart page
 * @param {Object} data - add product to tote from SFL cart ajax response
 */
function updateLoyaltyVouchers(data) {
    const { loyalty, subTotal } = data.totals;
    if (loyalty) {
        const { memberVouchers, memberOffers, redeemedVouchersCount, redeemedVoucherPrice, redeemedOffersCount, redeemedOffersPrice } = loyalty;
        const loyaltyVouchersSummarySection = queryFirst('.loyalty-cart-reward-section');
        const loyaltyOffersSummarySection = queryFirst('.loyalty-cart-offer-section');
        const applyVoucherCtas = queryAll('.apply-voucher-tote-cta');
        if (loyaltyVouchersSummarySection) {
            updateLoyaltyRewardsOffers(loyaltyVouchersSummarySection, memberVouchers, redeemedVouchersCount, redeemedVoucherPrice, '.js-vouchers-cta');
        }
        if (loyaltyOffersSummarySection) {
            updateLoyaltyRewardsOffers(loyaltyOffersSummarySection, memberOffers, redeemedOffersCount, redeemedOffersPrice, '.js-offers-cta');
        }
        applyVoucherCtas.forEach((applyCta) => {
            const voucherCardContentEl = applyCta.closest('.voucher-card-content');
            const voucherCardDisabledClass = 'voucher-card-disabled';
            if (subTotal === '$0' && !hasClass(applyCta, HIDDEN_CLASS)) {
                applyCta.disabled = true;
                addClass(voucherCardContentEl, voucherCardDisabledClass);
            } else {
                applyCta.disabled = false;
                removeClass(voucherCardContentEl, voucherCardDisabledClass);
            }
        });
    }
}
/**
 * Handle the spacing in Promo Section
 */
function couponsAndPromotionSpacingHandle() {
    const promoAccordionEl = queryFirst('.promo-accordion-container');
    if (promoAccordionEl) {
        const couponsAndPromos = queryFirst('.coupons-and-promos', promoAccordionEl);
        const couponItems = queryFirst('.coupon-price-adjustment', couponsAndPromos);
        const couponCodeField = queryFirst('.coupon-code-field');
        const couponError = queryFirst('.coupon-error');
        if (!couponItems) {
            addClass(couponsAndPromos, 'mt-0');
            addClass(couponCodeField, 'mb-2');
            removeClass(couponError, 'mt-2');
        } else {
            const couponErrorMessageEl = queryFirst('.coupon-error-message');
            const couponMissingErrorEl = queryFirst('.coupon-missing-error');
            addClass(couponError, 'mt-2');
            removeClass(couponsAndPromos, 'mt-0');
            if (couponErrorMessageEl && !couponErrorMessageEl.textContent && couponMissingErrorEl && couponMissingErrorEl.style.display === 'none') {
                removeClass(couponCodeField, 'is-invalid');
            }
            removeClass(couponCodeField, 'mb-2');
        }
    }
}

/**
 * Get the account details for Bolt User
 * @param {string} checkoutUrl - URL to redirect to Checkout page if error comes from the API call
 */
function fetchBoltAccountDetails(checkoutUrl) {
    const accountDetailUrl = queryFirst('.get-bolt-account-details').value;
    $.ajax({
        url: accountDetailUrl,
        method: 'GET',
        success: function ({ success, redirectUrl }) {
            //Remove the bolt logout cookie
            document.cookie = 'bolt_sfcc_session_logout=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = (success && redirectUrl) || checkoutUrl;
        },
        error: function () {
            window.location.href = checkoutUrl;
        }
    });
}
/**
 * Lilly Customization changes end
 */

/**
 * Initializes the event handlers
 */
function init() {
    $body.on('click', '.cart-nav-list.save-for-later-tab, .see-items-sfl', function (e) {
        const sflTab = queryFirst('.cart-nav-list.save-for-later-tab');
        if (!hasClass(sflTab, selectedTabClass)) {
            const myToteTab = queryFirst('.cart-nav-list.my-tote-tab');
            let toTab = 'saved for later';
            removeClass(myToteTab, selectedTabClass);
            addClass(sflTab, selectedTabClass);
            hideShowSummarySection();
            const queryParam = { view: 'saved' };
            const cartUrl = location.href;
            const urlWithParam = appendToUrl(cartUrl, queryParam);
            if (hasClass(this, 'js-see-items-sfl')) {
                const selectSFLTab = queryFirst('.js-save-for-later-select');
                const selectSFLContent = queryFirst('#saveForLater');
                const selectedMyToteTab = queryFirst('.js-my-tote-selected');
                const selectedMyToteContent = queryFirst('#myTote');
                removeClass([selectedMyToteTab, selectedMyToteContent], 'active');
                addClass([selectSFLTab, selectSFLContent], 'active');
                scrollTo($body.offset().top, 0);
                toTab += ': see items';
            }
            history.replaceState({}, '', urlWithParam);
            $body.trigger('tote:selectTab', { fromTab: 'my tote', toTab });
        }
    });

    $body.on('click', '.cart-nav-list.my-tote-tab', function (e) {
        if (!hasClass(this, selectedTabClass)) {
            const safeForLaterTab = queryFirst('.cart-nav-list.save-for-later-tab');
            removeClass(safeForLaterTab, selectedTabClass);
            addClass(this, selectedTabClass);
            hideShowSummarySection();
            history.replaceState({}, '', location.href.split('?')[0]);
            $body.trigger('tote:selectTab', { fromTab: 'saved for later', toTab: 'my tote' });
        }
    });

    $body.on('click', '.move-to-sfl', function (e) {
        e.preventDefault();
        $.spinner().start();
        const { href, actionUrl, pid, quantity, name, uuid, isGiftCard, isMonogram, giftCardAmount, monogramColor, monogramLocation, monogramStyle,
            monogramLetter1, monogramLetter2, monogramLetter3, monogramInitials, giftCardRecipientEmail, giftCardRecipientName, giftCardSenderName, giftCardEmailMessage } = this.dataset;
        const monogramOptions = {
            itemPersonalizationColor: monogramColor,
            itemPersonalizationLocation: monogramLocation,
            itemPersonalizationLetterStyle: monogramStyle,
            itemPersonalizationLetter1: monogramLetter1,
            itemPersonalizationLetter2: monogramLetter2,
            itemPersonalizationLetter3: monogramLetter3,
            itemPersonalizationLetters: monogramInitials
        }

        const form = {
            pid,
            qty: quantity,
            isGiftCard,
            isMonogram,
            giftCardAmount,
            monogramOptions: JSON.stringify(monogramOptions),
            giftCardRecipientEmail,
            giftCardRecipientName,
            giftCardSenderName,
            giftCardEmailMessage
        };
        $.ajax({
            url: href,
            type: 'post',
            data: form,
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    const { count, isLoggedIn } = data;
                    const signInSFLBottomWrapperEl = queryFirst('.sfl-login-wrapper');
                    const signInSFLBottomEl = queryFirst('.sfl-sign-in');
                    confirmDelete(actionUrl, pid, name, uuid);
                    $('.cart-page .cart-delete-confirmation-btn').trigger('click');
                    if (count !== 0) {
                        if (!isLoggedIn) {
                            removeClass([signInSFLBottomEl, signInSFLBottomWrapperEl], HIDDEN_CLASS);
                        }
                    }
                }
                else {
                    $.spinner().stop();
                }
            },
            error: function (err) {
                $.spinner().stop();
            }
        });
    });

    $body.on('click', '.remove-sfl-product', function (e) {
        e.preventDefault();
        const { action, pid, name, uuid, quantity } = this.dataset;
        const url = appendToUrl(action, { pid, uuid, qty: quantity });
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    const { count, emptySFLMsg, continueShopURL, continueShopMsg, signInMsg, isLoggedIn, signInSFLURL } = data;
                    const signInSFLBottomEl = queryFirst('.sfl-login-wrapper');
                    const itemsCountSFL = queryFirst('.cart-items-count .item-count-sfl');
                    const numberItemsSFL = queryFirst('.number-of-items-sfl');
                    const removedSFLProductCard = queryFirst('.sfl-product-info.uuid-' + uuid);
                    itemsCountSFL.textContent = count;
                    numberItemsSFL.textContent = count;
                    removedSFLProductCard.remove();
                    if (count === 0) {
                        const moveAllToCartCTA = queryFirst('.move-all-items-to-tote');
                        const sflProductCardItems = queryFirst('.sfl-cart-products');
                        addClass(moveAllToCartCTA, HIDDEN_CLASS);
                        const emptySFLMarkup = emptySFLTemplates(emptySFLMsg, continueShopURL, continueShopMsg, signInMsg, signInSFLURL);
                        sflProductCardItems.outerHTML = emptySFLMarkup;
                        const loginSFLBtn = queryFirst('.sfl-empty .sfl-login-btn');
                        if (isLoggedIn) {
                            addClass(loginSFLBtn, HIDDEN_CLASS);
                        }
                        else {
                            addClass(signInSFLBottomEl, HIDDEN_CLASS);
                            removeClass(loginSFLBtn, HIDDEN_CLASS);
                        }
                    }
                }
            },
            error: function (err) {
                $.spinner().stop();
            }
        });
        confirmDelete(action, pid, name, uuid);
    });

    $body.on('click', '.remove-product', function (e) {
        e.preventDefault();
        const { action, pid, name, uuid, tealium } = this.dataset;
        confirmDelete(action, pid, name, uuid, tealium);
    });

    $body.on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        const { actionUrl, productID, productName, uuid } = data;
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('.cart-container, .mini-cart-container').on('cart:updateTotals', function (e, data) {
        const { basket } = data;
        const { bonusProductLineItems } = basket;
        updateCartTotals(basket, true);
        if (bonusProductLineItems) {
            const { nonOptionBonusProducts = [], hasOptionBonusProducts } = bonusProductLineItems;

            const hasNonOptionsBonusProducts = !!nonOptionBonusProducts.length;
            const hasBonusProduct = hasNonOptionsBonusProducts || hasOptionBonusProducts;

            if (!hasNonOptionsBonusProducts) {
                remove(queryAll('.gwp-no-option-bonus'));
            }

            if (!hasOptionBonusProducts) {
                remove(queryAll('.gwp-option-bonus'));
            }

            if (!hasBonusProduct) {
                remove(queryAll('.gwp-container'));
            }
        }
        enableOrDisableCheckoutButton();
    });

    $body.on('click', '.cart-page .cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        const { target } = e;
        const { pid, action, uuid } = target.dataset;
        const urlParams = { pid, uuid };
        const url = appendToUrl(action, urlParams);
        const cartProductCard = queryFirst('.card.product-info.uuid-' + uuid);
        const storePickupGroupElement = cartProductCard.closest('.store-pickup-group');
        const cartShipProductsEl = cartProductCard.closest('.cart-ship-products');
        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                updateGWP(data);
                const { saveForLater, isSFLEnabled, basket, saveForLaterHtml } = data;
                if (isSFLEnabled) {
                    const productCountSFL = saveForLater.count || 0;
                    const cartSummaryEl = queryFirst('.cart-sfl-summary');
                    const cartWithSFLItemsTotal = queryFirst('.cart-items-count .item-count-tote');
                    if (cartWithSFLItemsTotal) {
                        cartWithSFLItemsTotal.textContent = basket.numItems;
                    }
                    $('.cart-items-count .item-count-sfl')
                        .empty()
                        .append(productCountSFL);
                    $('.number-of-items-sfl')
                        .empty()
                        .append(productCountSFL);
                    if (basket.items.length === 0) {
                        addClass(cartSummaryEl, HIDDEN_CLASS);
                    }
                    else {
                        removeClass(cartSummaryEl, HIDDEN_CLASS);
                    }

                    if (saveForLater && getNestedValue(saveForLater, 'items.length')) {
                        const sflCartProducts = queryFirst('.sfl-cart-products');
                        if (sflCartProducts) {
                            sflCartProducts.innerHTML = saveForLaterHtml;
                        }
                    }
                }
                if (basket.items.length === 0) {
                    if (isSFLEnabled) {
                        const emptyCartWithSFLElement = queryFirst('.js-cart-container .js-cart-empty');
                        const cartWithSFLElement = queryFirst('.js-cart-container .js-cart-with-items');
                        removeClass(emptyCartWithSFLElement, HIDDEN_CLASS);
                        addClass(cartWithSFLElement, HIDDEN_CLASS);
                    }
                    else {
                        emptyCart(basket.resources.emptyCartMsg);
                    }
                    $('.number-of-items').empty();
                    $('.minicart-quantity')
                        .empty()
                        .append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.basket.totals.discountsHtml);
                    updatePromoCodeDetails(data.basket.totals.discounts, data.basket.selectedShippingMethod);
                    updateCartTotals(data.basket);
                    updateLoyaltyVouchers(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts, data.basket.approachingDiscountsLabels);
                    const { updatedStoreQuantity, storeGroupMessage, enablePayPal } = data.basket;
                    if (cartShipProductsEl && !queryFirst('.cart-product-line-item', cartShipProductsEl)) {
                        cartShipProductsEl.remove();
                    }

                    const paypalContainerEl = queryFirst('.checkout-continue');
                    if (paypalContainerEl) {
                        if (enablePayPal) {
                            removeClass(paypalContainerEl, HIDDEN_CLASS);
                        } else {
                            addClass(paypalContainerEl, HIDDEN_CLASS);
                        }
                    }

                    if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                        const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                        if (storeGroupMessageElement) {
                            storeGroupMessageElement.textContent = storeGroupMessage;
                        }
                    } else if (storePickupGroupElement) {
                        storePickupGroupElement.remove();
                    }

                    if (data.basket.inStoreProductLineItems && !data.basket.inStoreProductLineItems.length) {
                        addClass(queryFirst('.cart-pickup-products'), HIDDEN_CLASS);
                        addClass(queryFirst('.mini-cart-store-title'), HIDDEN_CLASS);
                    }
                    $body.trigger('setShippingMethodSelection', data.basket);

                    $('.cart-container, .mini-cart-container').trigger('cart:updateTotals', data);
                    validateBasket(data.basket);
                    updateMonogramDetails(data.basket);
                }

                $body.trigger('cart:update');
                const applePayButton = queryFirst('.dw-apple-pay-button');
                if (data.basket.enableApplePay) {
                    removeClass(applePayButton, HIDDEN_CLASS);
                } else {
                    addClass(applePayButton, HIDDEN_CLASS);
                }
                // update Marketing Cloud Analytics trackCart event
                trackCart(data.mcAnalyticsArray);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $body.on('change', '.quantity-form > .quantity', function () {
        const self = this;
        const $this = $(self);
        var preSelectQty = $this.data('pre-select-qty');
        var quantity = $this.val();
        var productID = $this.data('pid');
        var url = $this.data('action');
        var uuid = $this.data('uuid');
        const storeId = $this.data('store-id');
        const cartProductCard = queryFirst('.card.product-info.uuid-' + uuid);
        const storePickupGroupElement = cartProductCard.closest('.store-pickup-group');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            storeId
        };
        url = appendToUrl(url, urlParams);
        const origPositionY = self.getBoundingClientRect().y;
        $this
            .parents('.card')
            .spinner()
            .start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    const { lineItemErrorMessage } = data;
                    const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                    if (lineItemErrorMessage && availabilityFlashMessageEl) {
                        removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                        availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                        setTimeout(() => {
                            addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                        }, 10000);
                        $(this).val(parseInt(preSelectQty, 10));
                    }
                    $.spinner().stop();
                } else {
                    $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                    const { updatedStoreQuantity, storeGroupMessage, cartProductsHtml } = data;
                    if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                        const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                        if (storeGroupMessageElement) {
                            storeGroupMessageElement.textContent = storeGroupMessage;
                        }
                    }
                    const lineItem = data.items.find(element => element.UUID === uuid);
                    if (lineItem && !storePickupGroupElement) {
                        const { giftWrapAvailableFlag, hasGiftWrap } = lineItem;
                        const cartStoreLinksSelector = '.product-info.uuid-' + uuid + ' .cart-store-links';
                        if (!giftWrapAvailableFlag) {
                            $('.gift-wrap-input[data-uuid="' + uuid + '"]').prop('checked', false);
                            $(cartStoreLinksSelector + ' form').addClass(HIDDEN_CLASS);
                            $(cartStoreLinksSelector + ' .seperator').addClass(HIDDEN_CLASS);
                        } else {
                            $('.gift-wrap-input[data-uuid="' + uuid + '"]').prop('checked', hasGiftWrap);
                            $(cartStoreLinksSelector + ' form').removeClass(HIDDEN_CLASS);
                            $(cartStoreLinksSelector + ' .seperator').removeClass(HIDDEN_CLASS);
                        }
                    }
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                    updateCartTotals(data);
                    updateLoyaltyVouchers(data);
                    updateApproachingDiscounts(data.approachingDiscounts, data.approachingDiscountsLabels);
                    queryFirst('.cart-products').innerHTML = cartProductsHtml;
                    updateGWP(data);
                    updateMonogramDetails(data);
                    validateBasket(data);

                    $body.trigger('cart:update');

                    // update Marketing Cloud Analytics trackCart event
                    trackCart(data.mcAnalyticsArray);

                    $.spinner().stop();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    const { errorMessage } = err.responseJSON;
                    createErrorNotification(errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $body.on('click', '.apply-voucher-tote-cta', function (e) {
        e.preventDefault();
        const applyRewardEl = this;
        const voucherCardEl = this.closest('.voucher-card-content');
        const removeRewardEl = queryFirst('.remove-voucher-tote-cta', voucherCardEl);
        const { couponCode } = voucherCardEl.dataset;
        const csrfTokenInput = queryFirst('.csrf-token', voucherCardEl);
        let csrfToken;
        if (csrfTokenInput) {
            csrfToken = csrfTokenInput.value;
        }
        $.spinner().start();
        $.ajax({
            url: this.dataset.action,
            type: 'POST',
            dataType: 'json',
            data: { csrf_token: csrfToken, couponCode },
            success: function (data) {
                if (data.error) {
                    const promoCodeFormControlEl = queryFirst('.promo-code-form .form-control');
                    addClass(promoCodeFormControlEl, INVALID_CLASS);
                    promoCodeFormControlEl.setAttribute('aria-describedby', 'invalidCouponCode');
                    $('.coupon-missing-error').hide();
                    couponsAndPromotionSpacingHandle();
                    $(queryFirst('.coupon-error-message'))
                        .empty()
                        .append(data.errorMessage);
                    $.spinner().stop();
                } else {
                    const { loyalty, discounts, discountsHtml } = data.totals;
                    if (loyalty && (loyalty.redeemedVouchersCount || loyalty.redeemedOffersCount)) {
                        addClass(applyRewardEl, HIDDEN_CLASS);
                        removeClass(removeRewardEl, HIDDEN_CLASS);
                        addClass(voucherCardEl, loyaltyVoucherApplied);
                        const { memberVouchers, memberOffers } = loyalty;
                        const voucherCardCtaEl = applyRewardEl.closest('.js-vouchers-cta');
                        const offerCardCtaEl = applyRewardEl.closest('.js-offers-cta');
                        if (voucherCardCtaEl) {
                            const { voucherCode } = voucherCardCtaEl.dataset;
                            memberVouchers.forEach(voucher => {
                                const { applied, code } = voucher;
                                if (applied && code === voucherCode) {
                                    voucherCardCtaEl.dataset.uuid = voucher.UUID;
                                }
                            });
                        }
                        if (offerCardCtaEl) {
                            const { voucherCode } = offerCardCtaEl.dataset;
                            memberOffers.forEach(offer => {
                                const { applied, code } = offer;
                                if (applied && code === voucherCode) {
                                    offerCardCtaEl.dataset.uuid = offer.UUID;
                                }
                            });
                        }
                        updateLoyaltyVouchers(data);
                        if (discounts) {
                            updateGWP(data);
                            updatePromoCodeDetails(discounts, data.selectedShippingMethod);
                        }
                        updateCartTotals(data);
                        if (!checkoutContainer) {
                            updateApproachingDiscounts(data.approachingDiscounts, data.approachingDiscountsLabels);
                            validateBasket(data);
                        } else {
                            $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                        }
                    }
                    $('.coupons-and-promos')
                        .empty()
                        .append(discountsHtml);
                    $.spinner().stop();
                }
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.csrfError) {
                    $body.trigger('csrf:error');
                } else {
                    createErrorNotification(err.errorMessage);
                }
                $.spinner().stop();
            }
        });
    });

    $body.on('click', '.remove-voucher-tote-cta', function (e) {
        e.preventDefault();
        const removeRewardEl = this;
        const voucherCardEl = this.closest('.voucher-card-content');
        const voucherCardCtaEl = queryFirst('.js-vouchers-cta', voucherCardEl);
        const offerCardCtaEl = queryFirst('.js-offers-cta', voucherCardEl);
        const applyRewardEl = queryFirst('.apply-voucher-tote-cta', voucherCardEl);
        const { action } = this.dataset;
        const { uuid, voucherCode } = voucherCardCtaEl ? voucherCardCtaEl.dataset : offerCardCtaEl.dataset;
        const url = appendToUrl(action, { code: voucherCode, uuid });

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                const { discounts } = data.totals;
                addClass(removeRewardEl, HIDDEN_CLASS);
                removeClass(applyRewardEl, HIDDEN_CLASS);
                removeClass(voucherCardEl, loyaltyVoucherApplied);
                updateLoyaltyVouchers(data);
                updateGWP(data);
                updateCartTotals(data);
                if (discounts) {
                    updatePromoCodeDetails(discounts, data.selectedShippingMethod);
                    if (!checkoutContainer) {
                        updateApproachingDiscounts(data.approachingDiscounts, data.approachingDiscountsLabels);
                        validateBasket(data);
                    } else {
                        $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                    }
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                    $.spinner().stop();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            couponsAndPromotionSpacingHandle();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                queryFirst('.coupon-code-field').value = '';
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message')
                        .empty()
                        .append(data.errorMessage);
                } else {
                    updateGWP(data);
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                    updateCartTotals(data);
                    updateLoyaltyVouchers(data);
                    if (!checkoutContainer) {
                        updateApproachingDiscounts(data.approachingDiscounts, data.approachingDiscountsLabels);
                        validateBasket(data);
                    } else {
                        $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                    }
                }
                if (!checkoutContainer) {
                    $('.cart-container').trigger('add-coupon', data);
                } else {
                    $(checkoutContainer).trigger('add-coupon', data);
                }
                couponsAndPromotionSpacingHandle();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.csrfError) {
                    $body.trigger('csrf:error');
                } else {
                    createErrorNotification(err.errorMessage);
                }
                $.spinner().stop();
            }
        });
        return false;
    });

    $body.on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                updateGWP(data);
                $('.coupon-uuid-' + uuid).remove();
                document.cookie = `dwapupreq=${new Date().getTime()}; `;
                updateCartTotals(data);
                if (!checkoutContainer) {
                    updateApproachingDiscounts(data.approachingDiscounts, data.approachingDiscountsLabels);
                    validateBasket(data);
                } else {
                    $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                }
                $('.coupons-and-promos')
                    .empty()
                    .append(data.totals.discountsHtml);
                updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                updateLoyaltyVouchers(data);
                couponsAndPromotionSpacingHandle();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $body.on('click', '.cart-page .bonus-product-button', function (event) {
        event.stopPropagation();
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $body.on('click', '.move-product-to-tote .move-sfl, .move-all-to-tote', function (event) {
        event.preventDefault();
        const self = this;
        const $this = $(self);
        const {
            qtyValue: quantity,
            pid: productID,
            action: url,
            uuid,
            isGiftCard,
            isMonogram: hasMonogram
        } = self.dataset;
        const cartProductCard = queryFirst(`.card.product-info.uuid${uuid ? '-' + uuid : ''}`);
        const storePickupGroupElement = uuid ? cartProductCard.closest('.store-pickup-group') : queryFirst('.store-pickup-group');
        const urlParams = {};
        if (productID) {
            urlParams.pid = productID;
        }
        if (quantity) {
            urlParams.qty = parseInt(quantity);
        }
        if (uuid) {
            urlParams.uuid = uuid;
        }
        if (isGiftCard) {
            urlParams.isGiftCard = isGiftCard;
        }
        if (hasMonogram) {
            urlParams.isMonogram = hasMonogram;
        }
        const origPositionY = self.getBoundingClientRect().y;
        if (hasClass(self, 'move-sfl')) {
            $this
                .parents('.card')
                .spinner()
                .start();
        }
        else {
            $.spinner().start();
        }
        $.ajax({
            url: url,
            type: 'post',
            data: urlParams,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    const { lineItemErrorMessage } = data;
                    const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                    if (lineItemErrorMessage && availabilityFlashMessageEl) {
                        removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                        availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                    }
                    $.spinner().stop();
                } else {
                    // When we come from Order Reciept page we do not have basket, so
                    // things like PayPal skip to initilize. As workaround for this case
                    // we force page to reload.
                    const currentBasketStatusElement = document.querySelector('[data-has-initial-basket]');
                    if (
                        currentBasketStatusElement &&
                        currentBasketStatusElement.getAttribute('data-has-initial-basket') === 'false'
                    ) {
                        trackCart(data.mcAnalyticsArray); // update Marketing Cloud Analytics trackCart event
                        history.replaceState({}, '', location.href.split('?')[0]);
                        location.reload();
                        return;
                    }

                    const emptyCartWithSFLElement = queryFirst('.js-cart-container .js-cart-empty');
                    const cartWithSFLElement = queryFirst('.js-cart-container .js-cart-with-items');
                    addClass(emptyCartWithSFLElement, HIDDEN_CLASS);
                    removeClass(cartWithSFLElement, HIDDEN_CLASS);
                    const { cartModel, count, toteCount } = data;
                    const { updatedStoreQuantity, storeGroupMessage, cartProductsHtml } = cartModel;
                    queryFirst('.cart-items-count .item-count-sfl').textContent = count;
                    queryFirst('.cart-items-count .item-count-tote').textContent = toteCount;
                    if (toteCount > 0) {
                        hideShowSummarySection();
                    }
                    if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                        const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                        if (storeGroupMessageElement) {
                            storeGroupMessageElement.textContent = storeGroupMessage;
                        }
                    }
                    const couponsPromosEl = queryFirst('.coupons-and-promos');
                    if (couponsPromosEl) {
                        couponsPromosEl.innerHTML = cartModel.totals.discountsHtml;
                    }
                    updatePromoCodeDetails(cartModel.totals.discounts, cartModel.selectedShippingMethod);
                    updateCartTotals(cartModel);
                    updateLoyaltyVouchers(cartModel);
                    updateApproachingDiscounts(cartModel.approachingDiscounts, cartModel.approachingDiscountsLabels);
                    updateGWP(cartModel);
                    const cartProductElement = queryFirst('.cart-products');
                    if (cartProductElement) {
                        cartProductElement.innerHTML = cartProductsHtml;
                        const seeAllItemElement = queryFirst('.see-all-items-wrapper');
                        removeClass(seeAllItemElement, HIDDEN_CLASS);
                    }
                    updateMonogramDetails(cartModel);
                    updateSaveForLaterDetails(data);
                    validateBasket(cartModel);
                    $body.trigger('cart:update');

                    // update Marketing Cloud Analytics trackCart event
                    trackCart(data.mcAnalyticsArray);

                    $.spinner().stop();
                    if (!hasClass(self, 'move-all-to-tote')) {
                        const originalScroll = document.defaultView.scrollY;
                        const newPositionY = self.getBoundingClientRect().y;
                        scrollTo(originalScroll + newPositionY - origPositionY, 0);
                    }
                    else {
                        scrollTo($body.offset().top, 0);
                    }
                }
            },
            error: function (err) {
                const { responseJSON = {} } = err;
                const { errorMessage, redirectUrl } = responseJSON;
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
                else {
                    createErrorNotification(errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $body.on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $body.on('product:afterAttributeSelect', function (_e, response) {
        const responseContainer = response.container[0];
        const { product } = response.data;
        const { categoryUrl, isCartPage } = responseContainer.dataset;
        const availabilityMessageEl = queryFirst('.product-availability .availability-message-text', responseContainer);

        if (!product.available && isCartPage && categoryUrl) {
            const message = availabilityMessageEl.innerText;
            availabilityMessageEl.innerHTML = cartTemplates.cartAvailabilityMessageOOS(message, categoryUrl);
        }

        const updateCartButton = queryFirst('.update-cart-product-global', responseContainer);
        if (updateCartButton) {
            updateCartButton.disabled = !product.readyToOrder || !product.available || product.isDirectlyPurchasable === false;
        }
    });

    $body.on('product:notifyMeHidden', function (_e, productContainer) {
        const updateCartButton = queryFirst('.update-cart-product-global', productContainer);
        removeClass(updateCartButton, 'd-none');
    });

    $body.on('product:notifyMeShown', function (_e, productContainer) {
        const updateCartButton = queryFirst('.update-cart-product-global', productContainer);
        addClass(updateCartButton, 'd-none');
    });

    $body.on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $body.on('change', '.options-select', function () {
        var selectedOptionValueId = $(this)
            .children('option:selected')
            .data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $body.on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        const { target } = e;
        const buttonContainer = target.closest('.cart-and-ipay');
        const updateCartHiddenField = queryFirst('.update-cart-url', buttonContainer);
        const updateProductUrl = updateCartHiddenField.value;
        const { selectedQuantity, selectedOption, uuid } = updateCartHiddenField.dataset;
        const { storeId } = target.dataset;
        const form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOption,
            storeId
        };
        const cartProductCard = queryFirst('.card.product-info.uuid-' + uuid);
        $('#quickViewModal')
            .spinner()
            .start();

        const giftCardFormEl = buttonContainer.closest('.gift-card');
        if (giftCardFormEl) {
            updateFormObjectForGiftCard(form, giftCardFormEl);
        }

        $(this)
            .parents('.card')
            .spinner()
            .start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    if (data.error) {
                        const { lineItemErrorMessage } = data;
                        const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                        if (lineItemErrorMessage && availabilityFlashMessageEl) {
                            removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                            availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                            setTimeout(() => {
                                addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                            }, 10000);
                        }
                        $('#quickViewModal')
                            .modal('hide')
                            .spinner()
                            .stop();
                    } else if (data.success) {
                        $('#quickViewModal')
                            .modal('hide')
                            .spinner()
                            .stop();

                        $('.coupons-and-promos')
                            .empty()
                            .append(data.cartModel.totals.discountsHtml);
                        updatePromoCodeDetails(data.cartModel.totals.discounts, data.cartModel.selectedShippingMethod);
                        updateCartTotals(data.cartModel);
                        updateLoyaltyVouchers(data.cartModel);
                        updateApproachingDiscounts(data.cartModel.approachingDiscounts, data.cartModel.approachingDiscountsLabels);
                        updateAvailability(data.cartModel, uuid);
                        updateProductDetails(data, uuid, target.dataset.giftWrapAvailable);
                        updateGWP(data.cartModel);
                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        validateBasket(data.cartModel);

                        const { enablePayPal, enableApplePay } = data.cartModel;

                        const paypalContainerEl = queryFirst('.checkout-continue');
                        if (paypalContainerEl) {
                            if (enablePayPal) {
                                removeClass(paypalContainerEl, HIDDEN_CLASS);
                            } else {
                                addClass(paypalContainerEl, HIDDEN_CLASS);
                            }
                        }

                        const applePayButton = queryFirst('.dw-apple-pay-button');
                        if (applePayButton) {
                            if (enableApplePay) {
                                removeClass(applePayButton, HIDDEN_CLASS);
                            } else {
                                addClass(applePayButton, HIDDEN_CLASS);
                            }
                        }

                        $body.trigger('cart:update');
                    } else {
                        formValidation(giftCardFormEl, data);
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    const cartPage = $('.cart-page');

    // Event listener for home delivery method link in cart page
    cartPage.on('click', '.home-delivery-link', event => {
        const { target } = event;
        const { uuid, pid, actionUrl } = target.dataset;
        const quantity = queryFirst('.quantity', target.closest('.cart-product-line-item')).value;
        const cartProductCard = queryFirst('.card.product-info.uuid-' + uuid);
        $.spinner().start();
        getJSON(
            actionUrl,
            'POST',
            {
                uuid,
                pid,
                quantity
            },
            data => {
                if (data.error) {
                    const { lineItemErrorMessage } = data;
                    const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                    if (lineItemErrorMessage && availabilityFlashMessageEl) {
                        removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                        availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                        setTimeout(() => {
                            addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                        }, 10000);
                    }
                    $.spinner().stop();
                } else {
                    const { cartProductsHtml, totals } = data;
                    const { discountsHtml } = totals;
                    const couponsEl = queryFirst('.coupons-and-promos');

                    if (couponsEl && discountsHtml) {
                        couponsEl.innerHTML = discountsHtml;
                    }
                    if (cartProductsHtml) {
                        document.cookie = `dwapupreq=${new Date().getTime()}; `;
                        queryFirst('.cart-products').innerHTML = cartProductsHtml;
                        updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                        updateCartTotals(data);
                        updateLoyaltyVouchers(data);
                        validateBasket(data);
                        $body.trigger('cart:update');
                        $.spinner().stop();
                    }
                }
            },
            () => {
                $.spinner().stop();
            }
        );
    });

    // Event listener for product gift wrap checkbox
    cartPage.on('click', '.gift-wrap-input', event => {
        const { target } = event;
        const { pid, uuid, actionUrl, ispu } = target.dataset;
        let isGiftWrap = target.checked;
        let isGiftwrapISPU = false;
        let removeGwpISPU = false;
        if (ispu === 'true') {
            if (isGiftWrap) {
                isGiftWrap = false;
                isGiftwrapISPU = true;
            } else {
                removeGwpISPU = true;
            }
        }
        const quantity = queryFirst('.quantity', target.closest('.cart-product-line-item')).value;
        $.spinner().start();
        getJSON(
            actionUrl,
            'POST',
            {
                uuid,
                pid,
                quantity,
                isGiftWrap,
                isGiftwrapISPU,
                removeGwpISPU
            },
            data => {
                document.cookie = `dwapupreq=${new Date().getTime()}; `;
                updateCartTotals(data);
                enableOrDisableCheckoutButton();
                $.spinner().stop();
            },
            () => {
                $.spinner().stop();
            }
        );
    });

    $body.on('checkout:updateCheckoutView', function (e, data) {
        const { totals, shipping } = data.order;
        const { discounts, discountsHtml } = totals;
        const couponsEl = queryFirst('.coupons-and-promos');

        if (couponsEl && discountsHtml) {
            couponsEl.innerHTML = discountsHtml;
        }
        if (discounts.length) {
            shipping.forEach(eachShipping => {
                const { selectedShippingMethod } = eachShipping;
                if (selectedShippingMethod && !selectedShippingMethod.storePickupEnabled) {
                    updatePromoCodeDetails(discounts, selectedShippingMethod);
                }
            });
        }
    });

    $body.on('click', '.loyalty-cart-coupons-collapse', function (e) {
        const loyaltyCouponsAccordionBtn = this;
        const couponsAccordionContainerEl = queryFirst('#vouchers-accordion');
        const couponsCardBodyEl = queryFirst('.card-body', couponsAccordionContainerEl);
        const { totalCoupons } = loyaltyCouponsAccordionBtn.dataset;
        const totalCouponsNum = parseInt(totalCoupons, 10);
        const cardBodyMarginTop = 'mt-3';
        if (totalCouponsNum > 2 && !hasClass(loyaltyCouponsAccordionBtn, 'collapsed')) {
            if (couponsCardBodyEl) {
                removeClass(couponsCardBodyEl, cardBodyMarginTop);
            }
            addClass(loyaltyCouponsAccordionBtn, 'collapsed-voucher-padding');
        } else {
            removeClass(loyaltyCouponsAccordionBtn, 'collapsed-voucher-padding');
            if (couponsCardBodyEl) {
                addClass(couponsCardBodyEl, cardBodyMarginTop);
            }
        }
    });

    const checkoutContinueCta = queryFirst('.checkout-continue-section .checkout-btn');
    if (checkoutContinueCta) {
        checkoutContinueCta.addEventListener('click', e => {
            e.preventDefault();
            const { target } = e;
            const { href, isBoltUserLoggedIn } = target.dataset;
            if (isBoltUserLoggedIn === 'true' && boltCheckoutAccountExistEl) {
                if (lpRegisteredUserEmail) {
                    const checkBoltAccountUrl = boltCheckoutAccountExistEl.value + '=' + encodeURIComponent(lpRegisteredUserEmail);
                    $.ajax({
                        url: checkBoltAccountUrl,
                        method: 'GET',
                        success(data) {
                            if (data && data.has_bolt_account) {
                                fetchBoltAccountDetails(href);
                            } else {
                                window.location.href = href;
                            }
                        },
                        error: function () {
                            window.location.href = href;
                        }
                    });
                } else {
                    fetchBoltAccountDetails(href);
                }
            } else {
                window.location.href = href;
            }
        });
    }

    $body.on('click', '.bolt-checkout-cta', function (e) {
        e.preventDefault();
        checkAccountAndFetchDetail();
    });

    enableOrDisableCheckoutButton();

    // Afterpay
    require('afterpay/afterpay')({
        anchors: '.grand-total-container',
        observerTargets: '.grand-total-container',
        priceTargets: '.grand-total-value',
        renderMode: 'adjacent'
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    window.setTimeout(function () {
        base.revealRecommendations();
        $body.trigger('search:updateProducts');
    }, 2000);

    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    };
    window.addEventListener('resize', throttle(() => {
        hideShowSummarySection();
    }));
}

export { updateCartTotals, updatePromoCodeDetails, updateApproachingDiscounts, validateBasket };
export default init;
