'use strict';

(function () {
    const { queryFirst } = require('./domUtil');
    const { body } = document;
    const oneTrustSettings = queryFirst('#onetrust-settings') || { dataset: {} };
    const { onetrustEnabled, onetrustDoNotSellFormUrl, onetrustPrivacyFormUrl } = oneTrustSettings.dataset;

    if (!onetrustEnabled || onetrustEnabled === 'false') return;

    // Cookie preferences links
    body.addEventListener('click', e => {
        const { target } = e;

        if (!target.closest('.cookie-preferences') || !window.OneTrust) return;

        e.preventDefault();
        OneTrust.ToggleInfoDisplay();
    });

    if (!onetrustDoNotSellFormUrl || !onetrustPrivacyFormUrl) return;

    // Privacy form links
    const { dialogTemplate, oneTrust: oneTrustTemplates } = require('./templates');
    const doNotSellFormSelector = '.onetrust-do-not-sell-link';
    const privacyFormSelector = '.onetrust-privacy-link';
    const privacyManagementHeading = 'Privacy Management';
    const oneTrustDialogId = 'onetrust-privacy-dialog';
    let oneTrustDialog = queryFirst(`#${oneTrustDialogId}`);

    const showDialog = content => {
        if (!oneTrustDialog) {
            oneTrustDialog = document.createElement('div');
            oneTrustDialog.id = oneTrustDialogId;
            document.body.appendChild(oneTrustDialog);
        }

        // Close any open OneTrust dialog
        try { OneTrust.Close(); } catch (e) { }

        $(oneTrustDialog).html(content).find('.modal').modal('show');
    };

    const doNotSellDialog = dialogTemplate({
        buttons: [],
        className: 'onetrust-do-not-sell-dialog',
        modalContentHeading: privacyManagementHeading,
        modalContentBody: `${oneTrustTemplates.privacyDialogCookieBanner}<iframe src="${onetrustDoNotSellFormUrl}"></iframe>`,
        slideOut: true
    });

    const privacyDialog = dialogTemplate({
        buttons: [],
        className: 'onetrust-privacy-dialog',
        modalContentHeading: privacyManagementHeading,
        modalContentBody: `${oneTrustTemplates.privacyDialogCookieBanner}<iframe src="${onetrustPrivacyFormUrl}"></iframe>`,
        slideOut: true
    });

    body.addEventListener('click', e => {
        const { target } = e;

        if (target.closest(doNotSellFormSelector)) {
            e.preventDefault();
            showDialog(doNotSellDialog);
        } else if (target.closest(privacyFormSelector)) {
            e.preventDefault();
            showDialog(privacyDialog);
        }
    });
})();