// This file includes functions which are exposed to the window object.
// A custom event is dispatched at the end of this file which will allow
// other scripts (e.g. content assets) to be able to time execution without polling.

import { initEmailLightbox, checkLightboxExcludedPages, arrivedViaEmailLink, submitSubscription } from './components/emailLightbox';
import { getCookieMap, setCookie } from './domUtil';
import { getNestedValue } from './util';

if (!window.lillyUtils) window.lillyUtils = {};

// Create global reference for Interaction Studio to be able to initialize the email lightbox
window.LP_initEmailCapture = initEmailLightbox;

// Expose key functions globally -- to be used by email gate/any other email content assets
window.lillyUtils.common = {
    getCookieMap,
    setCookie,
    getNestedValue
};

window.lillyUtils.email = {
    checkLightboxExcludedPages,
    arrivedViaEmailLink,
    submitSubscription
};

window.lillyUtils.ready = true; // if external scripts miss the event, they can check this instead
document.body.dispatchEvent(new Event('lillyUtils:ready'));
