'use strict';

import { queryAll, isInViewport } from './domUtil';
import scrollReveal from 'scrollreveal';

const $document = $(document);
const animate = '.search-results .animate-up';
let srElements = queryAll(animate);

$document.ready(function () {
    const animateUp = {
        distance: '15%',
        origin: 'bottom',
        opacity: 0.2,
        viewFactor: 0.25
    };
    const animatElements = () => {
        scrollReveal().reveal(animate, animateUp);

        // remove animation from visible elements to fix load flicker
        srElements.forEach(element => {
            if (isInViewport(element)) {
                scrollReveal().clean(element);
            }
        });
    };
    animatElements();

    $('body').on('search:updateProducts', function () {
        srElements = queryAll(animate);
        animatElements();
    });
});
