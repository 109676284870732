'use strict';

// Overridden OOTB toolTip to support tooltips that are dynamically injected

const $body = $('body');
const toolTipSelector = '.info-icon';

module.exports = function () {
    $body.on('mouseenter focusin', toolTipSelector, function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $body.on('mouseleave focusout', toolTipSelector, function () {
        $(this).find('.tooltip').addClass('d-none');
    });
};
