'use strict';

const { queryFirst, queryAll, addClass, removeClass } = require('../domUtil');

/**
 * Initializes Pills Menu click listeners
 */
function initPillsMenuListeners() {
    $('.customer-service, .rendering-template').on('click', '.pill-card-btn, .top-tabs a', (event) => {
        const { target } = event;
        const showClass = 'show';
        const activeClass = 'active';
        const mobileMenuClass = '.pills-card-selector';
        let menu = target.closest(mobileMenuClass);
        let isMobile;
        let cardId;
        let topTabs;

        if (menu) {
            isMobile = true;
            cardId = target.dataset.targetId;
        } else {
            isMobile = false;
            cardId = target.getAttribute('href').slice(1);
            topTabs = target.closest('.top-tabs');
            menu = topTabs && queryFirst(mobileMenuClass, topTabs.parentNode);
        }

        const selectedCard = document.getElementById(cardId);

        if (menu && selectedCard) {
            const { parentNode: menuParent } = menu;
            topTabs = topTabs || queryFirst('.top-tabs', menuParent);
            const tabContainer = queryFirst('.tab-content', menuParent);

            if (topTabs && tabContainer) {
                // Always update mobile menu element
                queryFirst('.pills-card-select-btn', menu).textContent = target.textContent;

                if (isMobile) {
                    // When mobile, update desktop menu elements as well
                    removeClass(queryAll('a', topTabs), activeClass);
                    addClass(queryFirst(`a[href="#${cardId}"]`, topTabs), activeClass);

                    // and update the currently selected card -- desktop interaction takes care of this elsewhere
                    removeClass(queryAll('.tab-pane', tabContainer), showClass, activeClass);
                    addClass(selectedCard, showClass, activeClass);
                }
            }
        }
    });
}

initPillsMenuListeners();
