import { getISOCodeByLocale } from './util';
const { queryFirst, addClass, scrollTo } = require('lilly/domUtil');
const { errorTextTemplate } = require('lilly/templates');
const showClass = 'show';

/**
 * making an ajax call to sfcc backend to clear bolt account data
 */
async function logout(e, customerEmail) {
    const boltPublishableKey = queryFirst('.bolt-publishable-key').value;
    const locale = queryFirst('.bolt-locale').value;

    const boltEmbedded = Bolt(boltPublishableKey, {
        language: getISOCodeByLocale(locale)
    });
    await boltEmbedded.helpers.logout();
    const url = queryFirst('.data-bolt-platform-side-logout-url').value;
    $.spinner().start();

    let requestObj = {
        url: url,
        method: 'POST',
        success: function (data) {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.message) {
                const errorElement = queryFirst('.error-message');
                errorElement.innerHTML = errorTextTemplate(err.responseJSON.message);
                addClass(errorElement, showClass);
                scrollTo(0);
            }
            $.spinner().stop();
        }
    };

    // add customerEmail to request to set it in Basket
    if (customerEmail) requestObj.data = { customerEmail: customerEmail };

    $.ajax(requestObj);
}

export { logout };
