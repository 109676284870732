'use strict';
const { removeClass, queryFirst, queryAll } = require('lilly/domUtil');
const smsElement = queryFirst('#smsEnabled');
const smsConfig = smsElement ? smsElement.dataset : {};
const isSMSEnabled = smsElement && smsElement.value === 'true';
const { smsNumber } = smsConfig;


/**
 * Initializes live agent configuration and events
 */
function init() {
    if (!isSMSEnabled) return;
    if (!smsNumber) return console.error('SMS Number is missing:', smsConfig); // eslint-disable-line no-console, consistent-return

    const smsElements = queryAll('.sms-chat-link');
    smsElements.forEach(element => {
        removeClass(element, 'd-none');
        let smsLink = queryFirst('a', element);
        if (smsLink) smsLink.href = 'sms:' + smsNumber;
    });
}

module.exports = {
    initSMS: init
};
