import { EventAccountRecognitionCheckPerformed } from './constant.js';
import { logout as autoLogout } from './boltHelpers.js';
import { getISOCodeByLocale } from './util.js';
import { HIDDEN_CLASS } from 'lilly/constants';

const { removeClass, queryFirst, addClass } = require('lilly/domUtil');

/**
 * Log the user into their bolt account
 * @param {string} email - input email
 * @returns {Promise} The returned promise to fetch account details
 */
async function login(email) {
    const loginModal = Bolt.create('login_modal', {
        autoDetectEmail: false
    });

    loginModal.attemptLogin({
        email: email
    }).then((authorizeResp) => {
        return authorizeCustomer(authorizeResp);
    });

}

export async function authorizeCustomer(authorizeResp) {
    if (!authorizeResp) {
        // If the user closed the OTP modal then change the payment method to CREDIT_CARD if it is BOLT_PAY 
        const checkoutContainer = queryFirst('.data-checkout-stage');
        if (checkoutContainer && ($('.payment-information').attr('data-payment-method-id') === 'BOLT_PAY')) {
            const creditCardContent = queryFirst('.credit-card-content');
            creditCardContent.id = 'credit-card-content';
            queryFirst('[data-method-id="CREDIT_CARD"] a').click();
            removeClass(queryFirst('.credit-card-tab-list'), 'payment-options-hide');
            addClass(queryFirst('.bolt-pay-tab-list'), HIDDEN_CLASS);
        }
        // trigger only in case if OTP modal was closed 
        if (email) {
            $('body').trigger('bolt:closeOtpModal');
        }
        return;
    }
    const OAuthResp = await authenticateUserWithCode(
        authorizeResp.authorizationCode,
        authorizeResp.scope
    );
    return getAccountDetails(OAuthResp.accessToken); // eslint-disable-line consistent-return
}

/**
 * This function uses the authCode and scope returned from authorizeWithEmail
 * after the user enters the 6 digits OTP code
 * It makes a call to Bolt-FetchOAuthToken controller to fetch Oauth token & refresh token
 * @param {string} authCode - auth Code
 * @param {string} scope - scope, both params are returned from authorizeWithEmail
 * @returns {Object} - an Ajax call to fetch oAuth token
 */
function authenticateUserWithCode(authCode, scope) {
    const authenticateUserUrl = $('.authenticate-bolt-user').val();
    const reqBody = {
        code: authCode,
        scope: scope
    };
    return $.ajax({
        url: authenticateUserUrl,
        method: 'GET',
        data: reqBody,
        error: function (jqXHR, error) {
            console.log(error);
        }
    });
}

/**
 * Get Account Details.
 * This function passes the Oauth token to bolt and retrieve the account details of a shopper
 * @param {string} oAuthToken - oAuth Token
 * @returns {Object} - an ajax call to fetch account details
 */
function getAccountDetails(oAuthToken) {
    const accountDetailUrl = $('.get-bolt-account-details').val();
    const reqBody = {
        bearerToken: oAuthToken
    };
    $.spinner().start();
    return $.ajax({
        url: accountDetailUrl,
        method: 'GET',
        data: reqBody,
        success: function (data) {
            $('body').trigger('bolt:proceedOtpModal');
            //Remove the bolt logout cookie
            document.cookie = 'bolt_sfcc_session_logout=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = data.redirectUrl;
        },
        error: function (jqXHR, error) {
            $.spinner().stop();
            console.log(error);
        }
    });
}

/**
 * Check Account And Fetch Detail
 * This function makes a call to bolt backend with the user email,
 * and log the user into their bolt account if the user has one
 * at the end of the login flow we redirect the user to the final page
 * where they can click place order so this function
 * doesn't return anything
 * @returns {void}
 */
export function checkAccountAndFetchDetail() {
    const emailInput = $('#email-guest');
    const customerEmail = emailInput.val();
    const checkBoltAccountUrl = $('.check-bolt-account-exist').val() + '=' + encodeURIComponent(customerEmail);
    const $accountCheckbox = $('#acct-checkbox');
    const $boltCheckboxInfo = $('#acc-checkbox-info');
    const boltCheckbox = queryFirst('#bolt-acct-check');
    const boltPublishableKey = queryFirst('.bolt-publishable-key').value;
    if ($accountCheckbox) {
        $accountCheckbox.show();
        $boltCheckboxInfo.show();
    }
    $.spinner().start();
    $.ajax({
        url: checkBoltAccountUrl,
        headers: { "X-Publishable-Key": boltPublishableKey },
        method: 'GET',
        success(data) {
            if (!data) return;
            if (data.has_bolt_account) {
                const localeEl = queryFirst('.bolt-locale');
                const locale = localeEl?.value || '';
                const boltKeyEl = queryFirst('.bolt-publishable-key');
                const boltKey = boltKeyEl?.value || '';
                Bolt(boltKey, { language: getISOCodeByLocale(locale) });
                login(customerEmail);
                if ($accountCheckbox) {
                    $accountCheckbox.hide();
                    $boltCheckboxInfo.hide();
                    if (boltCheckbox) {
                        boltCheckbox.checked = false;
                        $(boltCheckbox).trigger('change');
                    }
                }
            } else {
                const boltCheckboxContainer = queryFirst('#acct-checkbox');
                const isBoltUserLoggedIn = queryFirst('.bolt-is-shopper-logged-in');

                if (boltCheckboxContainer) {
                    $('body').trigger('bolt:createAccountCheckbox');
                    if (boltCheckbox) {
                        boltCheckbox.checked = true;
                        $(boltCheckbox).trigger('change');
                    }

                } else if (isBoltUserLoggedIn && isBoltUserLoggedIn.value === 'true') {
                    // sign out of a Bolt customer automatically if it is not a Bolt account
                    autoLogout(false, customerEmail);
                }
                $('.submit-customer').removeAttr('disabled'); // enable checkout button for non Bolt shopper
            }
            $.spinner().stop();
            window.BoltAnalytics.checkoutStepComplete(EventAccountRecognitionCheckPerformed, { hasBoltAccount: data.has_bolt_account, detectionMethod: 'email' });
        },
        error: function (jqXHR, error) {
            $.spinner().stop();
            console.log(error);
        }
    });
}

/**
 * making an ajax call to sfcc backend to clear bolt account data
 */
export function logout() {
    const url = $('.data-bolt-platform-side-logout-url').val();
    $.ajax({
        url: url,
        method: 'POST',
        success: function (data) {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.message) {
                $('.error-message').show();
                $('.error-message-text').text(err.responseJSON.message);
            }
        }
    });
}

/**
 * Get cookie from browser
 * @param {string} cookieName - cookie name
 * @returns {string} cookie value
 */
export function getCookie(cookieName) {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) { // eslint-disable-line no-plusplus
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
