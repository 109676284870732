/**
 * FIND
 * FROM: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find#Polyfill
 * FOR:  IE11
 */
if (!Array.prototype.find) {
    /* eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            if (this == null) {
                /* eslint new-cap: ["error", { "capIsNew": false }]*/
                throw TypeError('"this" is null or not defined');
            }

            var o = Object(this);
            var len = o.length >>> 0;

            if (typeof predicate !== 'function') {
                /* eslint new-cap: ["error", { "capIsNew": false }]*/
                throw TypeError('predicate must be a function');
            }

            var thisArg = arguments[1];

            var k = 0;

            while (k < len) {
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                k++;
            }

            return undefined;
        },
        configurable: true,
        writable: true
    });
}

/**
 * FIND
 * FROM: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
 * FOR:  IE9+ 
 */
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}


/**
 * CLOSEST
 * FROM: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 * FOR:  IE9+
 */
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

/**
 * FOREACH
 * FROM: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
 * FOR:  IE11
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * Object.entries()
 * From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
 * For: IE9+
 */
if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array

        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
    };
}

/**
 * REMOVE
 * FROM: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove#Polyfill
 * FOR:  IE9+
 */
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode === null) {
                    return;
                }
                this.parentNode.removeChild(this);
            }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

/**
 * INCLUDES
 * FROM: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#polyfill
 * FOR: IE9+
 */
if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      'use strict';
  
      if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp');
      } 
      if (start === undefined) { start = 0; }
      return this.indexOf(search, start) !== -1;
    };
  }

if(!Promise.prototype.finally) {
    Promise.prototype.finally = Promise.prototype.finally || {
        finally (fn) {
            const onFinally = value => Promise.resolve(fn()).then(() => value);
            return this.then(
            result => onFinally(result),
            reason => onFinally(Promise.reject(reason))
            );
        }
    }.finally;
}
