/**
 * OOTB code.
 * DRP-221 removed suggestions hide on click on body, change .siblings to .closest.find
 * DRP-818 hide popular search on resutls injection
 */

'use strict';

const { addClass, hasClass, removeClass, queryFirst, queryAll, renderFragment, getCookieMap, debounce } = require('../domUtil');
const { appendToUrl, setItemInLocalStorage } = require('../util');
const { HIDDEN_CLASS, SELECTED_CLASS, KEYCODE_UP, KEYCODE_DOWN, KEYCODE_ENTER, EVENT_KEY_ENTER, SEARCH_TYPE, SEARCH_SUBMIT, SEARCH_SUGGESTIONS, POPULAR_SEARCHES } = require('../constants');
const minChars = 3;
const DIRECTION_DOWN = 1;
const DIRECTION_UP = -1;
const DISPLAY_CLASS = 'd-sm-block';
const searchCache = {};
const { body } = document;
const $header = $('header');
const { lp_hsc_enabled: akamaiEnabled, lp_hsc_search: searchCaching, lp_cache_hash: lpCacheHash, lp_group_hash: lpGroupHash } = getCookieMap();
const isCachingEnabled = (akamaiEnabled === 'true' && searchCaching && lpCacheHash && lpGroupHash);

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {HTMLElement} element - Search input field DOM element
 * @return {HTMLElement} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(element) {
    const form = element.closest('.search-form');
    return form && queryFirst('.suggestions-wrapper', form);
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {HTMLElement} element - The input element associated with this search
 * @return {boolean} - Whether DOM element is inside div.search-mobile
 */
function isMobileSearch(element) {
    return !!(element && element.closest('.search-mobile'));
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    removeClass(body, 'modal-open');
    $header.siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {HTMLElement} element - The input element associated with this search
 */
function applyModals(element) {
    if (!isMobileSearch(element)) return;

    addClass(body, 'modal-open');
    $header.siblings().attr('aria-hidden', 'true');

    const wrapper = getSuggestionsWrapper(element);
    addClass(queryAll('.suggestions', wrapper), 'modal');
}

/**
 * Tear down Suggestions panel
 * @param {HTMLElement} element - The target element that prompted this method to fire
 */
function tearDownSuggestions(element) {
    const form = element.closest('form');
    const searchField = queryFirst('input.search-field', form);
    const wrapper = getSuggestionsWrapper(element);
    const suggestions = queryFirst('.search-mobile .suggestions', wrapper);

    searchField.value = '';
    wrapper.textContent = '';

    clearModals();

    if (suggestions) {
        delete suggestions.dataset.scrollBound;
        $(suggestions).unbind('scroll');
    }
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch)
            .removeClass(iconSearch)
            .addClass(iconSearchClose)
            .attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose)
            .removeClass(iconSearchClose)
            .addClass(iconSearch)
            .attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {HTMLElement} element - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(element) {
    const $element = $(element);

    $('.more-below')[$element.scrollTop() + $element.innerHeight() >= $element[0].scrollHeight ? 'fadeOut' : 'fadeIn']();
}

/**
 * Positions Suggestions panel on page
 *
 * @param {HTMLElement} element - The input element associated with this search.
 */
function positionSuggestions(element) {
    if (!isMobileSearch(element)) return;

    const $element = $(element);
    const top = $element.offset().top;
    const outerHeight = $element.outerHeight();
    const wrapper = getSuggestionsWrapper(element);
    const suggestions = queryFirst('.suggestions', wrapper);

    suggestions.style.top = `${top + outerHeight}px`;

    handleMoreContentBelowIcon(element);

    // Unfortunately, we have to bind this dynamically, as the live scroll event was not
    // properly detecting dynamic suggestions element's scroll event
    if (suggestions.dataset.scrollBound) return;
    suggestions.dataset.scrollBound = true;

    suggestions.scroll(function () {
        handleMoreContentBelowIcon(this);
    });
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 * @param {HTMLElement} element - The input element associated with this search result.
 * @param {string|boolean} response - HTML string to render or false, if there were no results.
 */
function processResponse(element, response) {
    const wrapper = getSuggestionsWrapper(element);
    const popularSearch = queryFirst('.popular-search');

    // DRP-818 hide popular search on results injection
    if (response || element.value) {
        addClass(popularSearch, HIDDEN_CLASS);
    } else {
        removeClass(popularSearch, HIDDEN_CLASS);
    }

    wrapper.textContent = '';

    $.spinner().stop();

    if (!response || !~response.indexOf('suggestions-section-items')) {
        addClass(wrapper, HIDDEN_CLASS);
    } else {
        wrapper.appendChild(renderFragment(response));
        removeClass(wrapper, HIDDEN_CLASS);
        wrapper.style.display = 'block';

        const form = wrapper.closest('form');
        addClass(queryFirst('.reset-button', form), DISPLAY_CLASS);
        positionSuggestions(element);

        if (isMobileSearch(element)) {
            toggleSuggestionsIcon('close');
            applyModals(element);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        const suggestionsList = queryFirst('.suggestions .item', form);

        if (suggestionsList) {
            element.setAttribute('aria-describedby', 'search-result-count');
        } else {
            element.removeAttribute('aria-describedby');
        }
    }

    const searchSuggestionList = queryAll('.suggestions-section');
    if (searchSuggestionList) {
        searchSuggestionList.forEach(function (elm) {
            elm.addEventListener('click', handleItemClick);
        });
    }

    const searchField = queryFirst('input.search-field');
    if (searchField) {
        searchField.addEventListener('keydown', handleKeydown);
    }
}

/**
 * Retrieve suggestions
 *
 * @param {HTMLElement} element - Search field DOM element
 * @param {Event} e - event that triggered this function call
 */
function getSuggestions(element, e) {
    const query = $(element).val();
    const wrapper = getSuggestionsWrapper(element);

    if (query.length >= minChars) {
        const cacheEntry = searchCache[query];
        if (cacheEntry) {
            processResponse(element, cacheEntry);

            if (e.type === 'keyup' && e.key !== EVENT_KEY_ENTER && e.keyCode !== KEYCODE_ENTER) {
                $(body).trigger('search:suggestionsUpdated');
            }
        } else {
            const url = `${wrapper.dataset.url}${encodeURIComponent(query)}`;
            const akamaiUrl = isCachingEnabled ? appendToUrl(url, {
                'lp-cache-hash': lpCacheHash,
                'lp-group-hash': lpGroupHash
            }) : url;

            $.ajax({
                context: element,
                url: akamaiUrl,
                method: 'GET',
                success: function (html) {
                    searchCache[query] = html;
                    processResponse(element, html);
                    $(body).trigger('search:suggestionsUpdated');
                },
                error: function () { }
            });
        }
    } else {
        toggleSuggestionsIcon('search');
        removeClass(queryAll('.reset-button', element.parentElement), DISPLAY_CLASS);
        clearModals();

        wrapper.textContent = '';
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 * @param {HTMLElement} element - The input element that triggered this event
 * @param {Integer} direction - Takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(element, direction) {
    const form = element.closest('form');
    const suggestionsList = queryAll('.suggestions .item', form).filter(item => item.offsetWidth && item.offsetHeight);
    let selectedIndex = suggestionsList.findIndex(item => hasClass(item, SELECTED_CLASS));
    let selectedItem = selectedIndex !== -1 ? suggestionsList[selectedIndex] : null;
    const maxIndex = suggestionsList.length - 1;

    if (selectedItem) {
        removeClass(selectedItem, SELECTED_CLASS);
        selectedItem.removeAttribute('aria-selected');
    }

    if (direction === -1) {
        selectedIndex = (selectedIndex <= 0) ? maxIndex : selectedIndex - 1;
    } else {
        selectedIndex = (selectedIndex >= maxIndex) ? 0 : selectedIndex + 1;
    }

    selectedItem = suggestionsList[selectedIndex];
    addClass(selectedItem, SELECTED_CLASS);
    selectedItem.setAttribute('aria-selected', true);

    const ariaElement = selectedItem.id ? selectedItem : selectedItem.closest('[id]');

    element.setAttribute('aria-activedescendant', (ariaElement && ariaElement.id) || '');
}

// PT-10593 store search_type in local storage
/**
 * Listen for click on suggested items
 * @param {Event} e - event that triggered this function call
 */
function handleItemClick(e) {
    const target = e.target;
    const suggestionWrapper = target.closest('.suggestions-section');
    const suggestionTypeElem = queryFirst('.suggestions-section-title > span', suggestionWrapper);
    const searchType = suggestionTypeElem ? suggestionTypeElem.textContent.trim().toLowerCase() : SEARCH_SUGGESTIONS;
    setItemInLocalStorage(SEARCH_TYPE, searchType);
}

/**
 * Listen for the submit event on search filed
 * @param {Event} e - event that triggered this function call
 */
function handleKeydown(e) {
    if (e.key === EVENT_KEY_ENTER || e.keyCode === KEYCODE_ENTER) {
        setItemInLocalStorage(SEARCH_TYPE, SEARCH_SUBMIT);
    }
}

module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        const suggestionsList = $('.suggestions .item');
        const selectedItems = suggestionsList.filter('.selected');

        if (selectedItems.length) {
            e.preventDefault();
            selectedItems.find('a')[0].click();
        }

        const searchField = queryFirst('.search-field', e.target);
        const searchText = searchField && searchField.value.trim();

        if (searchText) {
            searchField.value = searchText;
        }
    });

    const searchFields = queryAll('input.search-field');

    if (searchFields.length) {
        const debounceSuggestions = debounce(getSuggestions, 400, {
            condition: element => !!searchCache[element.value],
            callback: element => processResponse(element, false)
        });

        const handleSearchInput = function (e) {
            const { currentTarget } = e;
            switch (e.which) {
                case KEYCODE_DOWN:
                    e.preventDefault();
                    handleArrow(currentTarget, DIRECTION_DOWN);
                    break;
                case KEYCODE_UP:
                    e.preventDefault();
                    handleArrow(currentTarget, DIRECTION_UP);
                    break;
                default:
                    debounceSuggestions(currentTarget, e);
                    break;
            }
        };

        searchFields.forEach(field => {
            ['keyup', 'focus'].forEach(eventType => {
                field.addEventListener(eventType, handleSearchInput);
            });
        });
    }

    // DRP-221 removed suggestions hide on click on body
    $(body).on('click touchend', '.search-mobile button.fa-close', e => {
        e.preventDefault();
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions(e.target);
    });

    $('.search-form .reset-button').on('click', function () {
        $(this).removeClass(DISPLAY_CLASS);
        removeClass(queryFirst('.popular-search'), HIDDEN_CLASS);
    });
};
