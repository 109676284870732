'use strict';
const { queryFirst, queryAll } = require('../domUtil');
const $body = $('body');

// Code to close the nearest boostrap modal
$body.on('click', '.lp-slideout-modal .close-current-modal', function (event) {
    const currentModal = $(event.target).closest('.lp-slideout-modal');

    if (currentModal.length) {
        currentModal.modal('hide');
    }
});

// Code to stop scrolling behind the modal and modal moving issue in nested modal scenario
$body.on('hidden.bs.modal', function () {
    if (queryFirst('.modal.show')) {
        $body.addClass('modal-open');
    } else {
        queryAll('.modal-backdrop').forEach(element => element.remove());
        $('html, body').css({ overflow: 'visible' });
    }
});