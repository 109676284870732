import { HIDDEN_CLASS, INVALID_CLASS } from '../constants';
import { addClass, queryAll, queryFirst, removeClass } from '../domUtil';
import { formatPhoneNumber } from '../util';

const LS_PAGES_VISITED = 'lightbox_emailcapture_pages';
const LS_DISMISSED = 'lightbox_emailcapture_dismissed';
const LS_SUBMITTED = 'lightbox_emailcapture_submitted';

const dialog = queryFirst('#email-flyin-v1');
const emailForm = queryFirst('.flyin-email-form', dialog);
const emailInput = queryFirst('.flyin-email-input', dialog);
const mobileInput = queryFirst('.flyin-mobile-input', dialog);
const errorMessages = queryFirst('.flyin-email-errors', dialog);
const subscribeButton = queryFirst('.email-submit-button', dialog);
const formElem = queryFirst('.email-lightbox-form', dialog);
const thanksElem = queryFirst('.email-subscribe-thanks', dialog);

function formatNumberHandler () {
    return formatPhoneNumber(mobileInput);
}
/**
* Closes the email sign-up lightbox
*/
function closeLightbox() {
    localStorage.setItem(LS_DISMISSED, true);
    emailForm.removeEventListener('submit', submitHandler);

    if (mobileInput) {
        mobileInput.removeEventListener('keyup', formatNumberHandler);
    }
}

/**
 * Checks whether the current page in on the excluded pages list (meaning the lghtbox won't display)
 * @param {...string} [additionalPages] - any additional page strings to check
 * @returns {boolean} whether the current page is on the excluded pages list
 */
function checkLightboxExcludedPages(...additionalPages) {
    const { location } = document;
    const excludedPages = ['/club-lilly/', '/register/', '/login/', '/help/', '/account/', '/contact-us/', ...additionalPages];
    return excludedPages.some(page => location.href.includes(page));
}

/**
 * Checks whether the user arrived on the current page by clicking a link in an SFMC email
 * @returns {boolean} whether the user arrived using an SFMC link
 */
function arrivedViaEmailLink() {
    const { location } = document;
    const qsParams = new URLSearchParams(location.search);
    return qsParams.get('utm_source') === 'sfmc';
}

/**
 * Submits a subscription request
 * @param {string} url - the endpoint to submit to
 * @param {string} email - the email to subscribe
 * @param {string} [source] - the source of the subscription
 * @param {string} [mobile] - the mobile number to subscribe
 * @returns {Object} response data object
 */
async function submitSubscription(url, email, source, mobile) {
    const formData = new FormData();
    formData.append('emailId', email);
    if (source) formData.append('source', source);
    if (mobile) formData.append('mobileNum', mobile);

    const response = await fetch(url, {
        method: 'POST',
        body: formData
    });

    if (!response.ok) {
        console.error(`emailLightbox: subscribe response was not OK (HTTP ${response.status})`);
        return {
            success: false,
            msg: 'Something went wrong, please try again'
        };
    }

    return await response.json();
}

/**
 * Displays an error message on the form
 * @param {string} message - The error message to be displayed
 * @returns {boolean} false
 */
function showError(message) {
    errorMessages.textContent = message;
    addClass(emailInput, INVALID_CLASS);
    return false;
}

/**
 * Shows a success message to the user
 */
function showSuccess() {
    localStorage.setItem(LS_SUBMITTED, true);

    if (formElem && thanksElem) {
        addClass(formElem, HIDDEN_CLASS);
        removeClass(thanksElem, HIDDEN_CLASS);
        thanksElem.setAttribute('role', 'alert');
    }
}

/**
 * Displays a message to the user based on the form submission response
 * @param {Object} response - form submission response JSON object
 */
function displayMessage(response) {
    const $body = $(document.body);

    $.spinner().stop();
    if (response.success) {
        showSuccess();
        $body.trigger('subscribe:success');
    } else {
        showError(response.msg);
        $body.trigger('subscribe:fail');
    }
    subscribeButton.disabled = false;
    subscribeButton.removeAttribute('aria-disabled');
}

async function submitHandler(e) {
    e.preventDefault();
    const emailId = emailInput.value;
    const mobileId = mobileInput ? mobileInput.value : '';
    const { href: subscribeUrl } = subscribeButton.dataset;
    const subSource = window.LP_EmailSignup_Source || 'promoLightbox';

    if (!emailId) return showError(emailInput.dataset.missingError);
    if (!subscribeUrl) {
        console.error('emailLightbox: no subscription URL found on button');
        return false;
    }

    emailInput.classList.remove(INVALID_CLASS);
    subscribeButton.disabled = true;
    subscribeButton.setAttribute('aria-disabled', 'true');
    $.spinner().start();

    const data = await submitSubscription(subscribeUrl, emailId, subSource, mobileId);

    displayMessage(data);
}

/**
 * Initializes email lightbox event listeners
 * @param {boolean} [forceInit=false] - forces the lightbox to show on-demand on non-excluded pages.
 */
function initEmailLightbox(forceInit = false) {
    let pagesVisited = parseInt(localStorage.getItem(LS_PAGES_VISITED), 10) || 0;
    const { referrer } = document;
    const isLandingPage = !referrer.includes('lillypulitzer.com') && !referrer.includes('.dx.');
    const isExcludedPage = checkLightboxExcludedPages();
    const isFromSFMC = arrivedViaEmailLink();

    if (isFromSFMC) {
        // if this parameter exists, the user got here from an email link --
        // set submitted to true in case they are on a different device
        localStorage.setItem(LS_SUBMITTED, true);
    }

    const hasDismissed = localStorage.getItem(LS_DISMISSED) === 'true';
    const hasSubmitted = localStorage.getItem(LS_SUBMITTED) === 'true';

    if (isExcludedPage || (!forceInit && (hasDismissed || hasSubmitted))) return;

    if (forceInit || !isLandingPage) {
        localStorage.setItem(LS_PAGES_VISITED, ++pagesVisited);

        if (forceInit || pagesVisited >= 1) {
            if (!dialog || !emailForm || !emailInput || !errorMessages || !subscribeButton) return;

            const smsEnabled = queryFirst('.data-signup-sms-enabled');
            if (!smsEnabled) {
                addClass(queryFirst('.enable-sms-capture'), HIDDEN_CLASS);
            }

            if (mobileInput) {
                mobileInput.addEventListener('keyup', formatNumberHandler);
            }

            emailForm.addEventListener('submit', submitHandler);

            const $dialog = $(dialog);
            $dialog.on('hidden.bs.modal', closeLightbox);
            $dialog.modal('show');
        }
    }
}

export {
    initEmailLightbox,
    checkLightboxExcludedPages,
    arrivedViaEmailLink,
    submitSubscription
};
