import { queryFirst, addClass, removeClass } from "../domUtil";

/**
 * This method toggles the staggered ctas in content assets
 */
function toggleStaggeredCta() {
    if (window.matchMedia('(max-width: 768px)').matches) {
        $('.shop-carousel').scroll((e) => {
            const { target } = e;
            const scrollPosition = target.scrollLeft;
            const staggeredCta1 = queryFirst('.staggered-cta-1');
            const staggeredCta2 = queryFirst('.staggered-cta-2');
            const hiddenClass = 'd-none';
            if (staggeredCta1 && staggeredCta2) {
                if (scrollPosition > target.offsetWidth / 2) {
                    addClass(staggeredCta1, hiddenClass);
                    removeClass(staggeredCta2, hiddenClass);
                }
                else {
                    addClass(staggeredCta2, hiddenClass);
                    removeClass(staggeredCta1, hiddenClass);
                }
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    toggleStaggeredCta();
});

window.addEventListener('resize', toggleStaggeredCta);
