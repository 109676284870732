'use strict';

const Cleave = require('cleave.js').default;

$('input.postal-code').toArray().forEach(function (field) {
    new Cleave(field, {
        numericOnly: true,
        delimiter: '-',
        delimiterLazyShow: true,
        blocks: [5, 4]
    });
});
