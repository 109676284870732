'use strict';

const { jQuery } = window;

/**
 * Animates scrolling to the specified Y coordinate
 *
 * @param {number} y - The Y coordinate to scroll to
 * @param {number} duration - The duration of the animation in milliseconds
 * @param {function} callback - The callback to run when the animation is complete
 * @returns {JQuery}
 */
jQuery.fn.scrollTo = function(y, duration = 600, callback = function(){}) {
    return $(this).stop().animate({ scrollTop: y }, duration, callback);
};
