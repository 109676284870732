'use strict';

import { queryFirst } from './domUtil';
/**
 * Function to lazyLoad Curalate
 */
function initCuralateLazyLoad() {
    const obj = queryFirst('.curalate-content');
    if (!obj) {
        return;
    }
    const observer = new IntersectionObserver(
        (element) => {
            if (element[0].intersectionRatio <= 0) {
                return;
            }
            try {
                loadCuralate(); // eslint-disable-line
            } catch (e) {
                console.error("Error: Global Curalate function 'loadCuralate()' not loaded in time. Check Business Manager to ensure Curalate Tag is correct."); //eslint-disable-line
            }
            observer.disconnect();
        },
        { rootMargin: '200px 0px 0px' }
    );

    observer.observe(obj);
}

initCuralateLazyLoad();
